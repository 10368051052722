import { Flex, Icon, Button, Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { listEvents } from "../actions/eventsActions";
import EventListCard from "../components/EventListCard";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const EventsListScreen = () => {
  const dispatch = useDispatch();

  const eventsList = useSelector((state) => state.eventsList);
  const { loading, error, events } = eventsList;

  const eventsDelete = useSelector((state) => state.eventsDelete);
  const { success: successDelete } = eventsDelete;

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(listEvents());
  }, [dispatch, successDelete]);

  return (
    <Flex direction="column" w="full">
      <Flex h="full" direction="column" w={{ sm: "full", md: "full" }}>
        <Flex
          py="2"
          bg="#dfcaa4"
          px={{ sm: "5px", md: "40px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            fontSize="xl"
            fontWeight="bold"
            as={RouterLink}
            to="/home"
            color="white"
            alignItems="center"
            gap="2"
          >
            <Icon as={FaRegArrowAltCircleLeft} boxSize="6" color="white" />
            {t("Home")}
          </Flex>
          <Button color="black" bg="#ab846a" as={RouterLink} to={"/events/add"}>
            <Icon as={IoAddCircleOutline} boxSize="5" /> {t("Add Event")}
          </Button>
        </Flex>

        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          // <Flex pt='5px' direction={{ sm: 'column', md: 'row' }} gap='2' flexWrap='wrap' px={{ sm: '5px', md: '66px' }}>
          <div className="grid grid-cols-1 md:grid-cols-3 pt-2 px-4 md:px-16 gap-4">
            {events.event.map((e) => (
              <EventListCard key={e._id} events={e} progId={null} />
            ))}
          </div>
          // </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EventsListScreen;
