import {
  Flex,
  Icon,
  Button,
  Heading,
  Image,
  Text,
  Box,
  AspectRatio,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { IoPencilSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { listProgramDetails } from "../actions/programsActions";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const ProgramsScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const programsDetails = useSelector((state) => state.programsDetails);
  const { loading, error, programs } = programsDetails;

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(listProgramDetails(id));
  }, [dispatch, id]);

  return (
    <>
      <Flex direction="column" w="full">
        <Flex h="full" direction="column" w={{ sm: "full", md: "full" }}>
          <Flex
            bg="#dfcaa4"
            py="2"
            px={{ sm: "5px", md: "40px" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex
              fontSize="xl"
              fontWeight="bold"
              color="white"
              as={RouterLink}
              to="/programs"
              alignItems="center"
              gap="2"
            >
              <Icon as={FaRegArrowAltCircleLeft} boxSize="6" color="white" />
              {t("Programs")}
            </Flex>
            <Button
              as={RouterLink}
              to={`/programs/${id}/edit`}
              bgColor="#ab846a"
              color="black"
            >
              <Icon as={IoPencilSharp} boxSize="5" /> {t("Edit Program")}
            </Button>
          </Flex>

          {loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            <Flex pt="5px" direction="column" px={{ sm: "5px", md: "70px" }}>
              <Flex direction="column" pt="3">
                <Flex w="full" direction={{ sm: "column", md: "row" }} gap="3">
                  <Flex
                    direction="column"
                    gap="2"
                    py="2"
                    w={{ sm: "100%", md: "1/3" }}
                  >
                    <AspectRatio ratio={16 / 9}>
                      <Image
                        rounded="md"
                        src={programs.progImage}
                        alt={programs.name}
                        w={{ sm: "100%", md: "400px" }}
                        h={{ sm: "270px", md: "300px" }}
                        display={programs.progImage ? "flex" : "none"}
                      />
                    </AspectRatio>
                    <Box display={programs.thumbVid ? "flex" : "none"}>
                      {/* <video src={programs.thumbVid} controls /> */}
                      <ReactPlayer
                        url={programs.thumbVid}
                        controls
                        width="450px"
                        height="290px"
                      />
                    </Box>
                  </Flex>

                  <Flex direction="column">
                    <Flex
                      w="full"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Heading
                        as="h2"
                        width="70%"
                        fontSize="2xl"
                        fontWeight="bold"
                        color="teal.600"
                      >
                        {programs.name}
                      </Heading>

                      <Heading
                        as="h6"
                        fontSize="sm"
                        fontWeight="bold"
                        color="blue.600"
                      >
                        {`${new Date(programs.setTime).toLocaleString("de-DE", {
                          timeZone: "UTC",
                          hour12: false,
                        })}`}
                      </Heading>
                    </Flex>
                    <Flex direction="column" pt="2">
                      <Text fontWeight="bold">{programs.result}</Text>
                      <Text py={{ sm: "", md: "2" }} textAlign="justify">
                        {programs.description}
                      </Text>
                    </Flex>
                    <Flex
                      alignSelf="center"
                      py="5"
                      direction={{ sm: "column", md: "row" }}
                      gap="2"
                      justifyContent="center"
                    >
                      <Button
                        bg="black"
                        color="white"
                        _hover="none"
                        w="250px"
                        as={RouterLink}
                        to={`/program/${id}/tasks`}
                      >
                        {t("Go To Tasks")}
                      </Button>
                      <Button
                        bg="black"
                        color="white"
                        _hover="none"
                        w="250px"
                        as={RouterLink}
                        to={`/program/${id}/events`}
                      >
                        {t("Go To Events")}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
                {programs.media && (
                  <Flex direction="column" gap="2" pb="5">
                    <Heading fontSize="xl">Additional Media</Heading>
                    <Card maxW="md" bgColor="white">
                      <CardBody>
                        <Flex
                          display={
                            programs.media.type === "image" ? "flex" : "none"
                          }
                        >
                          <Image
                            src={programs.media.url}
                            alt={programs.media.name}
                            borderRadius="lg"
                          />
                        </Flex>

                        <Flex
                          display={
                            programs.media.type === "video" ? "flex" : "none"
                          }
                        >
                          {/* <video src={programs.media.url} controls /> */}
                          <ReactPlayer
                            url={programs?.media.url}
                            controls
                            width="450px"
                            height="290px"
                          />
                        </Flex>
                        <Text color="black" fontSize="xl" fontWeight="bold">
                          {programs.media.name}
                        </Text>

                        <Text color="black">{programs.media.description}</Text>
                      </CardBody>
                    </Card>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ProgramsScreen;
