import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Table,
	Image,
	Tbody,
	Text,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoCheckmarkCircleSharp,
	IoCloseCircleSharp,
} from 'react-icons/io5';
import { BsReply } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listDoubtUsers } from '../actions/userActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const DoubtListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userDoubtList = useSelector((state) => state.userDoubtList);
	const { loading, error, userDoubt } = userDoubtList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const { t } = useTranslation();

	useEffect(() => {
		if (userInfo && userInfo.isCoach) {
			dispatch(listDoubtUsers());
		} else {
			navigate('/login');
		}
	}, [dispatch, navigate, userInfo]);

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='4' bg='#dfcaa4' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Heading fontSize='xl' color='white'>
						{t("Questions")}
					</Heading>
				</Flex>
				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{ sm: '390px', md: 'full' }}>
						<Table variant='striped' colorScheme='gray' size='sm'>
							<Thead>
								<Tr>
									<Th>{t("IMAGE")}</Th>
									<Th>{t("NAME")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("EMAIL")}</Th>
									<Th>{t("POSITION")}</Th>
									<Th>{t("ORGANIZATION")}</Th>
									<Th>{t("IS COACH")}</Th>
									<Th>
										<Flex justifyContent='flex-end' mr={{ sm: '', lg: '9' }} gap='24px'>
											<Text>{t("Reply")}</Text>
										</Flex>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{userDoubt.userDoubtList.map((obj) => (
									<Tr>
										<Td>
											<Image src={obj.user.image} h='70px' w='70px' />
										</Td>
										<Td>{obj.user.name}</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>
											<a href={`mailto:${obj.user.email}`}>{obj.user.email}</a>
										</Td>
										<Td>{obj.user.position}</Td>
										<Td>{obj.user.orgName}</Td>
										<Td>
											{obj.user.isCoach ? (
												<Flex pl='3.5'>
													<Icon
														as={IoCheckmarkCircleSharp}
														color='green.600'
														w='8'
														h='8'
													/>
												</Flex>
											) : (
												<Flex pl='3.5'>
													<Icon
														as={IoCloseCircleSharp}
														color='red.600'
														w='8'
														h='8'
													/>
												</Flex>
											)}
										</Td>
										<Td>
											<Flex justifyContent='flex-end' alignItems='center'>
												<Button
													mr='4'
													as={RouterLink}
													to={`/doubt/${obj.doubtId}`}
													color='white'
													colorScheme='teal'>
													<Text display={{ base: 'none', md: 'block' }}>{t("Reply")}</Text>
													<Icon display={{ base: 'block', md: 'none' }} as={BsReply} color='white' size='sm' />
												</Button>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
			</Flex>
		</Flex>
	);
};

export default DoubtListScreen;
