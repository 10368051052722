import axios from "axios";

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  BG_IMAGE_FAIL,
  BG_IMAGE_REQUEST,
  BG_IMAGE_SUCCESS,
  BG_IMAGE_UPDATE_FAIL,
  BG_IMAGE_UPDATE_REQUEST,
  BG_IMAGE_UPDATE_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_DOUBT_LIST_FAIL,
  USER_DOUBT_LIST_REQUEST,
  USER_DOUBT_LIST_SUCCESS,
  USER_LIST_DETAILS_FAIL,
  USER_LIST_DETAILS_REQUEST,
  USER_LIST_DETAILS_RESET,
  USER_LIST_DETAILS_SUCCESS,
  FEEDBACK_USER_LIST_FAIL,
  FEEDBACK_USER_LIST_REQUEST,
  FEEDBACK_USER_LIST_RESET,
  FEEDBACK_USER_LIST_SUCCESS,
  FEEDBACK_FAIL,
  FEEDBACK_REQUEST,
  FEEDBACK_RESET,
  FEEDBACK_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
} from "../constants/userConstants";
import serverIp from "../config/config";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${serverIp}/api/user/login`,
      { email, password },
      config
    );

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_LIST_DETAILS_RESET });
  dispatch({ type: FEEDBACK_USER_LIST_RESET });
  dispatch({ type: FEEDBACK_RESET });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_UPDATE_PROFILE_RESET });
};

export const bgImage = () => async (dispatch) => {
  try {
    dispatch({ type: BG_IMAGE_REQUEST });

    // const config = {
    // 	headers: {
    // 		'Content-Type': 'application/json',
    // 	},
    // };

    //src & config
    const { data } = await axios.get(`${serverIp}/api/user/getbg`);

    dispatch({ type: BG_IMAGE_SUCCESS, payload: data });
    localStorage.setItem("imgInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: BG_IMAGE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const bgImageUpdate = () => async (dispatch) => {
  try {
    dispatch({ type: BG_IMAGE_UPDATE_REQUEST });

    // const config = {
    // 	headers: {
    // 		'Content-Type': 'application/json',
    // 	},
    // };

    //src & config
    const { data } = await axios.get(`${serverIp}/api/user/getbg`);

    dispatch({ type: BG_IMAGE_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: BG_IMAGE_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/coach/users`, config);

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

//used by coach
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/coach/users/${id}`, config);

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

//for user account deletion by user himself
export const deleteAccount = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      `${serverIp}/api/user/deleteAccount`,
      { email, password },
      config
    );

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    let id = user._id;
    const { data } = await axios.put(
      `${serverIp}/api/coach/users/${id}/edit`,
      user,
      config
    );

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listDoubtUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DOUBT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/coach/doubt`, config);

    dispatch({ type: USER_DOUBT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_DOUBT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getListUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_DETAILS_REQUEST });

    const {
      userLogin: { userInfo }, // 2 level destructuring
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/coach/users/${id}/profile`,
      config
    );

    dispatch({ type: USER_LIST_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_LIST_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listFeedbackUsers = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEEDBACK_USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/coach/feedbackProg/${id}/feedbackUsers`,
      config
    );

    dispatch({ type: FEEDBACK_USER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: FEEDBACK_USER_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const feedbackDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEEDBACK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/coach/feedback/${id}`,
      config
    );

    dispatch({ type: FEEDBACK_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: FEEDBACK_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo }, // 2 level destructuring
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/user/profile`, config);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${serverIp}/api/user/profile`,
      user,
      config
    );

    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
