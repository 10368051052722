import axios from "axios";
import {
  TOKEN_UPDATE_REQUEST,
  TOKEN_UPDATE_SUCCESS,
  TOKEN_UPDATE_FAIL,
} from "../constants/fcmpnConstants";
import serverIp from "../config/config";

// Update a token
export const updateToken = (messagingToken) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOKEN_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${serverIp}/api/tokens`,
      { messagingToken },
      config
    );

    dispatch({ type: TOKEN_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TOKEN_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
