import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Table,
  Image,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
  IoPencilSharp,
  IoTrashBinSharp,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { deleteUser, listFeedbackUsers } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const FeedbackUserListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: progId } = useParams();

  const feedbackUserList = useSelector((state) => state.feedbackUserList);
  const { loading, error, users } = feedbackUserList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo && userInfo.isCoach) {
      dispatch(listFeedbackUsers(progId));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, successDelete, progId]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteUser(id));
    }
  };

  return (
    <Flex direction="column" w="full">
      <Flex h="full" direction="column" w={{ sm: "390px", md: "full" }}>
        <Flex
          py="4"
          bg="#dfcaa4"
          px={{ sm: "5px", lg: "40px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            fontSize="xl"
            fontWeight="bold"
            as={RouterLink}
            to="/feedProg"
            color="white"
            alignItems="center"
            gap="2"
          >
            <Icon as={FaRegArrowAltCircleLeft} boxSize="6" color="white" />
            {t("Home")}
          </Flex>
          <Heading fontSize="xl" color="white">
            {t("Users")}
          </Heading>
        </Flex>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Box
            bgColor="white"
            rounded="lg"
            shadow="lg"
            px="5"
            py="5"
            w={{ sm: "390px", md: "full" }}
          >
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>{t("IMAGE")}</Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("NAME")}
                  </Th>
                  <Th display={{ sm: "table-cell", md: "none" }}>
                    {t("DETAILS")}
                  </Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("EMAIL")}
                  </Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("POSITION")}
                  </Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("ORGANIZATION")}
                  </Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("IS COACH")}
                  </Th>
                  <Th>
                    <Text pl="3">{t("FEEDBACK")}</Text>
                  </Th>
                  <Th display={{ sm: "none", md: "table-cell" }}>
                    <Flex justifyContent="space-evenly" mr="5" gap="20px">
                      <Text pl="2">{t("EDIT")}</Text>
                      <Text>{t("DELETE")}</Text>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {users?.users?.map((user) => (
                  <Tr key={user.user._id}>
                    <Td>
                      <Image
                        src={user.user.image}
                        h={{ sm: "35px", lg: "70px" }}
                        w={{ sm: "35px", lg: "70px" }}
                      />
                    </Td>
                    <Td>
                      <Flex direction="column" gap="2px">
                        <Text fontSize="sm" fontWeight="bold">
                          {user.user.name}
                        </Text>
                        <Text
                          fontSize="smaller"
                          display={{ sm: "flex", md: "none" }}
                        >
                          {user.user.position}
                        </Text>
                        <Text
                          fontSize="smaller"
                          display={{ sm: "flex", md: "none" }}
                        >
                          {user.user.orgName}
                        </Text>
                      </Flex>
                    </Td>
                    <Td display={{ sm: "none", md: "table-cell" }}>
                      <a href={`mailto:${user.user.email}`}>
                        {user.user.email}
                      </a>
                    </Td>
                    <Td display={{ sm: "none", md: "table-cell" }}>
                      {user.user.position}
                    </Td>
                    <Td display={{ sm: "none", md: "table-cell" }}>
                      {user.user.orgName}
                    </Td>
                    <Td display={{ sm: "none", md: "table-cell" }}>
                      {user.user.isCoach ? (
                        <Flex>
                          <Icon
                            as={IoCheckmarkCircleSharp}
                            color="green.600"
                            w="8"
                            h="8"
                          />
                        </Flex>
                      ) : (
                        <Flex pl="3.5">
                          <Icon
                            as={IoCloseCircleSharp}
                            color="red.600"
                            w="8"
                            h="8"
                          />
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      <Button
                        mr="4"
                        as={RouterLink}
                        to={`/feedback/${user.feedbackId}`}
                        colorScheme="teal"
                      >
                        {t("Feedback")}
                      </Button>
                    </Td>
                    <Td display={{ sm: "none", md: "table-cell" }}>
                      <Flex justifyContent="space-evenly" alignItems="center">
                        <Button
                          mr="4"
                          as={RouterLink}
                          to={`/users/${user.user._id}/edit`}
                          colorScheme="teal"
                        >
                          <Icon as={IoPencilSharp} color="white" size="sm" />
                        </Button>
                        <Button
                          mr="4"
                          colorScheme="red"
                          onClick={() => deleteHandler(user.user._id)}
                        >
                          <Icon as={IoTrashBinSharp} color="white" size="sm" />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default FeedbackUserListScreen;
