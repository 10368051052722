import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoChevronDown } from "react-icons/io5";
import { MdOutlineLanguage } from "react-icons/md";

import { bgImage, login, deleteAccount } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import Loader from "../components/Loader";

const UserDeleteScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   let [searchParams] = useSearchParams();
  //   let redirect = searchParams.get("redirect") || "/home";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { loading: loadingDelete, error: errorDelete, success } = userDelete;

  //   const checkLanguage = () => {
  //     let lang = localStorage.getItem("languageMethod");
  //     if (!lang) {
  //       return "de";
  //     }
  //     return JSON.parse(lang);
  //   };

  //   const [lang, setLang] = useState(checkLanguage);

  //   const { t, i18n } = useTranslation();

  //   const changeLanguage = (language) => {
  //     localStorage.setItem("languageMethod", JSON.stringify(language));
  //     setLang(language);
  //   };

  //   useEffect(() => {
  //     i18n.changeLanguage(lang);
  //   }, [lang, i18n]);

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch(login(email, password));
    dispatch(deleteAccount(email, password));
    // dispatch(bgImage());
  };

  return (
    <Flex direction="column" as={Center} justifyContent="center" h="90vh">
      <Flex
        boxShadow="lg"
        border="1px"
        borderBottom="none"
        opacity="0.9"
        borderColor="black"
        as={Center}
        bg="#dfcaa4"
        roundedTop="md"
        w={{ sm: "380px", md: "500px" }}
      >
        <Image src="Logo1.png" alt="image" h={{ sm: "45px", md: "60px" }} />
      </Flex>
      <Flex>
        <FormContainer>
          <Text as="h2" mb="8" fontSize="3xl" color="black">
            {`Delete Account`}
          </Text>
          {error && <Message type="error">{error}</Message>}

          <form onSubmit={submitHandler}>
            <Flex
              direction={{ sm: "column", md: "row" }}
              flexWrap={{ sm: "nowrap", md: "wrap" }}
              rowGap={{ sm: "10px", md: "15px" }}
              columnGap={{ sm: "0", md: "10px" }}
              w={{ sm: "330px", md: "450px" }}
            >
              <FormControl id="email">
                <FormLabel htmlFor="email" color="black">
                  {"Email address"}
                </FormLabel>
                <Input
                  color="black"
                  id="email"
                  borderColor="black"
                  type="email"
                  placeholder={"username@domain.com"}
                  _placeholder={{ color: "black", opacity: "0.7" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <FormControl id="password">
                <FormLabel htmlFor="password" color="black">
                  {"Password"}
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  borderColor="black"
                  placeholder="************"
                  _placeholder={{ color: "black", opacity: "0.7" }}
                  value={password}
                  color="black"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <Flex dir="row" justifyContent="space-between">
                <Button
                  type="submit"
                  mr="4"
                  bgColor="#ab846a"
                  color="black"
                  mt="4"
                  isLoading={loading}
                >
                  {`Delete`}
                </Button>

				{loadingDelete ? (
					<Loader />
				) : errorDelete ? (
					<Message type="error">{errorDelete}</Message>
				) : (
					<div>
						<h3 className="text-red-500 font-semibold">Account Deleted</h3>
					</div>
				)}
              </Flex>
            </Flex>
          </form>
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default UserDeleteScreen;
