import { Flex, Heading, Box, Text, Icon } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import Rating from '../components/Rating';
import { feedbackDetails as feedDetails } from '../actions/userActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const FeedBackScreen = () => {

	const dispatch = useDispatch();

	const { id } = useParams();

	const feedbackDetails = useSelector((state) => state.feedbackDetails);
	const { loading, error, feedback } = feedbackDetails;

	const feedbackUserList = useSelector((state) => state.feedbackUserList);
	const { users } = feedbackUserList;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(feedDetails(id));
	}, [dispatch, id]);

	let feedUser = '';
	users.users.forEach(obj => {
		if (obj.user._id === feedback.user) {
			feedUser = obj.user.name;
		}
	});

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='4' bg='#dfcaa4' px={{ sm: '5px', lg: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/feedProg' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
				</Flex>

				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Flex pt='5px' direction='column' gap='2' flexWrap='wrap' px={{ sm: '5px', md: '66px' }}>
						<Flex>
							<Heading>
								{feedback.feedbackQ}
							</Heading>
						</Flex>
						<Box>
							<Text>{t("Feedback Before")}</Text>
							<Rating value={feedback.scoreBefore} />
						</Box>
						<Box mb='4' pb='4' borderBottom='1px solid black'>
							<Text fontSize='lg' fontWeight='semibold'>{feedback.userFeedbackBefore}</Text>
						</Box>
						<Box>
							<Text>{t("Feedback After")}</Text>
							<Rating value={feedback.scoreAfter} />
						</Box>
						<Box>
							<Text fontSize='lg' fontWeight='semibold'>{feedback.userFeedbackAfter}</Text>
						</Box>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default FeedBackScreen;