import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import {
  aboutusDetails as aboutDetails,
  updateAboutUs,
} from "../actions/infoActions";
import { ABOUTUS_UPDATE_RESET } from "../constants/infoConstants";
import ImageCropper from "../components/ImageCropper";

const AboutUsEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [uploadingImg, setUploadingImg] = useState(false);
  const [isShown, setIsShown] = useState(true);

  const aboutusDetails = useSelector((state) => state.aboutusDetails);
  const { loading, error, aboutus } = aboutusDetails;

  const aboutUsUpdate = useSelector((state) => state.aboutUsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = aboutUsUpdate;

  const { t } = useTranslation();

  useEffect(() => {
    if (aboutus.aboutUs.length === 0) {
      dispatch(aboutDetails());
    }
    if (successUpdate) {
      dispatch({ type: ABOUTUS_UPDATE_RESET });
      navigate(`/aboutus`);
    }
    if (aboutus.aboutUs.length !== 0) {
      setTitle(aboutus.aboutUs[0].title);
      setDescription(aboutus.aboutUs[0].description);
      setImage(aboutus.aboutUs[0].image);
    }
  }, [dispatch, navigate, successUpdate, aboutus.aboutUs]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateAboutUs({
        title,
        image,
        description,
      })
    );
  };

  return (
    <Flex direction="column" mt="5px">
      {/* <Button w='100px' bg='black' color='white' _hover='none' as={RouterLink} to='/aboutus'>
				{t("Go Back")}
			</Button> */}

      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        px={{ sm: "4px", md: "" }}
      >
        <FormContainer>
          <Heading as="h1" mb="8" fontSize="3xl" color="black">
            {t("Edit AboutUs")}
          </Heading>

          {loadingUpdate && <Loader />}
          {errorUpdate && <Message type="error">{errorUpdate}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            //onSubmit={submitHandler}
            <form onSubmit={submitHandler}>
              <Flex
                direction={{ sm: "column", md: "row" }}
                flexWrap={{ sm: "nowrap", md: "wrap" }}
                rowGap={{ sm: "10px", md: "15px" }}
                columnGap={{ sm: "0", md: "10px" }}
              >
                {/* TITLE */}
                <FormControl id="title" isRequired>
                  <FormLabel color="black">{t("Title")}</FormLabel>
                  <Input
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter title")}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>

                {/* IMAGE */}
                <FormControl id="thumbimage" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Image")}</FormLabel>
                  <Flex justifyContent="center">
                    <ImageCropper
                      route="uploads/supportImg"
                      setIsShown={setIsShown}
                      thumbImage={image}
                      setThumbImage={setImage}
					  aspectRatio={16 / 9}
                    />
                  </Flex>
                  {uploadingImg && <Loader />}
                </FormControl>

                {/* DESCRIPTION */}
                <FormControl id="description" isRequired>
                  <FormLabel color="black">{t("Description")}</FormLabel>
                  <Input
                    as={Textarea}
                    color="black"
                    type="text"
                    h="167px"
                    borderColor="black"
                    placeholder={t("Enter description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>

                {/* <Button
								type='submit'
								isLoading={loading}
								colorScheme='teal'
								mt='4'>
								{t("Done")}
							</Button> */}
                <Flex gap="5" mt="6">
                  <Button
                    colorScheme="teal"
                    color="black"
                    type="submit"
                    isLoading={loading}
                  >
                    {t("Done")}
                  </Button>
                  <Button
                    colorScheme="red"
                    color="white"
                    _hover="none"
                    as={RouterLink}
                    to="/aboutus"
                  >
                    {t("Cancel")}
                  </Button>
                </Flex>
              </Flex>
            </form>
          )}
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default AboutUsEditScreen;
