import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Stack,
  Flex,
  Heading,
} from "@chakra-ui/react";
import {
  createCoupon,
  getCouponDetails,
  updateCoupon,
} from "../actions/couponActions";
import {
  COUPON_CREATE_RESET,
  COUPON_UPDATE_RESET,
} from "../constants/couponCostants";
import { useTranslation } from "react-i18next";
import { listEvents } from "../actions/eventsActions";
import { listPrograms } from "../actions/programsActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const CouponForm = () => {
  const { id } = useParams(); // Get ID from URL if editing
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [code, setCode] = useState("");
  const [discountType, setDiscountType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState(0);
  const [validFrom, setValidFrom] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [durationInHours, setDurationInHours] = useState("");
  const [durationInDays, setDurationInDays] = useState("");
  const [isValidForAllPrograms, setIsValidForAllPrograms] = useState(false);
  const [isValidForAllEvents, setIsValidForAllEvents] = useState(false);
  const [programme, setProgramme] = useState("");
  const [event, setEvent] = useState("");

  const couponDetails = useSelector((state) => state.couponDetails);
  const { loading, error, coupon } = couponDetails;

  const couponUpdate = useSelector((state) => state.couponUpdate);
  const { success: successUpdate } = couponUpdate;

  const couponCreate = useSelector((state) => state.couponCreate);
  const { success: successCreate } = couponCreate;

  const eventsList = useSelector((state) => state.eventsList);
  const { loading: loadingEvents, error: errorEvents, events } = eventsList;

  const programsList = useSelector((state) => state.programsList);
  const {
    loading: loadingPrograms,
    error: errorPrograms,
    programs,
  } = programsList;

  useEffect(() => {
    dispatch(listEvents());
    dispatch(listPrograms("Überblick", true));
    if (id) {
      if (!coupon || coupon._id !== id || successUpdate) {
        dispatch({ type: COUPON_UPDATE_RESET });
        dispatch(getCouponDetails(id));
      } else {
        setCode(coupon.code);
        setDiscountType(coupon.discountType);
        setDiscountValue(coupon.discountValue);
        setValidFrom(coupon.validFrom.split("T")[0]);
        setValidUntil(coupon.validUntil ? coupon.validUntil.split("T")[0] : "");
        setDurationInHours(coupon.durationInHours || "");
        setDurationInDays(coupon.durationInDays || "");
        setIsValidForAllPrograms(coupon.isValidForAllPrograms);
        setIsValidForAllEvents(coupon.isValidForAllEvents);
        setProgramme(coupon.programme ? coupon.programme._id : "");
        setEvent(coupon.event ? coupon.event._id : "");
      }
    }
  }, [dispatch, id, coupon, successUpdate]);

  useEffect(() => {
    if (successCreate || successUpdate) {
      dispatch({ type: COUPON_CREATE_RESET });
      dispatch({ type: COUPON_UPDATE_RESET });
      navigate("/coupons");
    }
  }, [successCreate, successUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const couponData = {
      code,
      discountType,
      discountValue,
      validFrom,
      validUntil,
      durationInHours: durationInHours ? Number(durationInHours) : undefined,
      durationInDays: durationInDays ? Number(durationInDays) : undefined,
      isValidForAllPrograms,
      isValidForAllEvents,
      programme: programme || undefined,
      event: event || undefined,
    };

    if (id) {
      dispatch(updateCoupon(id, couponData));
    } else {
      dispatch(createCoupon(couponData));
    }
  };

  return (
    <Flex direction="column" align="center" maxW="600px" mx="auto" mt="4">
      <Heading mb="4">{id ? t("Edit Coupon") : t("Add Coupon")}</Heading>
      <form onSubmit={submitHandler}>
        <Stack spacing="4">
          <FormControl id="code" isRequired>
            <FormLabel>{t("Code")}</FormLabel>
            <Input
              type="text"
              placeholder={t("Enter coupon code")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </FormControl>

          <FormControl id="discountType" isRequired>
            <FormLabel>{t("Discount Type")}</FormLabel>
            <Select
              placeholder={t("Select discount type")}
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
            >
              <option value="percentage">{t("Percentage")}</option>
              <option value="fixed">{t("Fixed")}</option>
            </Select>
          </FormControl>

          <FormControl id="discountValue" isRequired>
            <FormLabel>{t("Discount Value")}</FormLabel>
            <Input
              type="number"
              placeholder={t("Enter discount value")}
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
            />
          </FormControl>

          <FormControl id="validFrom" isRequired>
            <FormLabel>{t("Valid From")}</FormLabel>
            <Input
              type="date"
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
            />
          </FormControl>

          <FormControl id="validUntil">
            <FormLabel>{t("Valid Until")}</FormLabel>
            <Input
              type="date"
              value={validUntil}
              onChange={(e) => setValidUntil(e.target.value)}
            />
          </FormControl>

          <FormControl id="durationInHours">
            <FormLabel>{t("Duration in Hours")}</FormLabel>
            <Input
              type="number"
              placeholder={t("Enter duration in hours")}
              value={durationInHours}
              onChange={(e) => setDurationInHours(e.target.value)}
            />
          </FormControl>

          <FormControl id="durationInDays">
            <FormLabel>{t("Duration in Days")}</FormLabel>
            <Input
              type="number"
              placeholder={t("Enter duration in days")}
              value={durationInDays}
              onChange={(e) => setDurationInDays(e.target.value)}
            />
          </FormControl>

          <FormControl id="isValidForAllPrograms">
            <Checkbox
              isChecked={isValidForAllPrograms}
              onChange={(e) => setIsValidForAllPrograms(e.target.checked)}
            >
              {t("Valid for All Programs")}
            </Checkbox>
          </FormControl>

          <FormControl id="isValidForAllEvents">
            <Checkbox
              isChecked={isValidForAllEvents}
              onChange={(e) => setIsValidForAllEvents(e.target.checked)}
            >
              {t("Valid for All Events")}
            </Checkbox>
          </FormControl>

          {/* <FormControl id="programme">
            <FormLabel>{t("Programme")}</FormLabel>
            <Input
              type="text"
              placeholder={t("Enter programme ID")}
              value={programme}
              onChange={(e) => setProgramme(e.target.value)}
              isDisabled={isValidForAllPrograms}
            />
          </FormControl> */}
          <FormControl id="programme">
            <FormLabel>{t("Programme")}</FormLabel>
            {loadingPrograms ? (
              <Loader />
            ) : errorPrograms ? (
              <Message type="error">{errorPrograms}</Message>
            ) : (
              <Select
                placeholder={t("Select programme name")}
                value={programme}
                onChange={(e) => setProgramme(e.target.value)}
                isDisabled={isValidForAllPrograms}
              >
                {programs.programme.map((program) => (
                  <option value={program._id}>{program.name}</option>
                ))}
              </Select>
            )}
          </FormControl>

          {/* <FormControl id="event">
            <FormLabel>{t("Event")}</FormLabel>
            <Input
              type="text"
              placeholder={t("Enter event ID")}
              value={event}
              onChange={(e) => setEvent(e.target.value)}
              isDisabled={isValidForAllEvents}
            />
          </FormControl> */}

          <FormControl id="event">
            <FormLabel>{t("Event")}</FormLabel>
            {loadingEvents ? (
              <Loader />
            ) : errorEvents ? (
              <Message type="error">{errorEvents}</Message>
            ) : (
              <Select
                placeholder={t("Select Event Name")}
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                isDisabled={isValidForAllEvents}
              >
                {events.event.map((event) => (
                  <option value={event._id}>{event.name}</option>
                ))}
              </Select>
            )}
          </FormControl>

          <Button type="submit" colorScheme="teal" size="lg" mb="6">
            {id ? t("Update Coupon") : t("Create Coupon")}
          </Button>
        </Stack>
      </form>
    </Flex>
  );
};

export default CouponForm;
