import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  AspectRatio,
  Textarea,
  Card,
  CardBody,
  Link,
  CardFooter,
  Text,
  Divider,
  ButtonGroup,
  Select,
} from "@chakra-ui/react";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { listTaskDetails, updateTasks } from "../actions/tasksActions";
import { TASKS_UPDATE_RESET } from "../constants/tasksConstants";
import serverIp from "../config/config";
import UploadFile from "../components/UploadFile";
import ImageCropper from "../components/ImageCropper";
import AlertDialogExample from "../components/AlertDialog";
import ReactPlayer from "react-player";

const TaskEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: taskId } = useParams();

  const [taskImage, setTaskImage] = useState([]);
  const [thumbImage, setThumbImage] = useState([]);
  const [thumbVideo, setThumbVideo] = useState([]);
  const [doc, setDoc] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [result, setResult] = useState("");
  const [sequence, setSequence] = useState(1);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [uploadingVid, setUploadingVid] = useState(false);
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [globalScope, setGlobalScope] = useState(false);
  const [mediaImages, setMediaImages] = useState([]); //{ url: null, name: '', type: '', description: '' }
  const [mediaVideos, setMediaVideos] = useState([]);
  const [mediaImageObject, setMediaImageObject] = useState({
    url: null,
    name: "",
    type: "",
    description: "",
  });
  const [mediaVideoObject, setMediaVideoObject] = useState({
    url: null,
    name: "",
    type: "",
    description: "",
  });
  const [mediaImageEdit, setMediaImageEdit] = useState(false);
  const [mediaVideoEdit, setMediaVideoEdit] = useState(false);
  const [mediaImageEditIndex, setMediaImageEditIndex] = useState(0);
  const [mediaVideoEditIndex, setMediaVideoEditIndex] = useState(0);
  const [isShown, setIsShown] = useState(true);

  const tasksDetails = useSelector((state) => state.tasksDetails);
  const { loading, error, tasks } = tasksDetails;

  const tasksUpdate = useSelector((state) => state.tasksUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = tasksUpdate;

  const { t } = useTranslation();

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: TASKS_UPDATE_RESET });
      navigate(`/tasks/${taskId}`);
    } else {
      if (!tasks.title || tasks._id !== taskId) {
        dispatch(listTaskDetails(taskId));
      } else {
        setTitle(tasks.title);
        setResult(tasks.result);
        setSequence(tasks.sequence);
        setTaskImage(tasks.taskImage);
        setThumbImage(tasks.thumbImage);
        setThumbVideo(tasks.thumbVid);
        setGlobalScope(tasks.globalScope);
        setDescription(tasks.description);
        setMediaImages(tasks.images === null ? [] : tasks.images);
        setMediaVideos(tasks.videos === null ? [] : tasks.videos);
        setDoc(tasks.document);
      }
    }
  }, [dispatch, navigate, taskId, tasks, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateTasks({
        _id: taskId,
        title,
        result,
        sequence,
        taskImage,
        globalScope,
        thumbImage,
        thumbVid: thumbVideo,
        document: doc,
        images: mediaImages?.length === 0 ? null : mediaImages,
        videos: mediaVideos?.length === 0 ? null : mediaVideos,
        description,
      })
    );
  };

  const onMediaImageChange = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaImages];
    updatedMediaList[index] = {
      type: type,
      url: url,
      name: name,
      description: description,
    };
    setMediaImages(updatedMediaList);
    setMediaImageObject({ url: null, name: "", type: "", description: "" });
  };

  const onMediaVideoChange = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaVideos];
    updatedMediaList[index] = {
      type: type,
      url: url,
      name: name,
      description: description,
    };
    setMediaVideos(updatedMediaList);
    setMediaVideoObject({ url: null, name: "", type: "", description: "" });
  };

  const onMediaImageSave = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaImages, { type, url, name, description }];
    setMediaImages(updatedMediaList);
    setMediaImageObject({ url: null, name: "", type: "", description: "" });
  };

  const onMediaVideoSave = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaVideos, { type, url, name, description }];
    setMediaVideos(updatedMediaList);
    setMediaVideoObject({ url: null, name: "", type: "", description: "" });
  };

  const onMediaImageEdit = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaImages];
    updatedMediaList[index] = {
      type: type,
      url: url,
      name: name,
      description: description,
    };
    setMediaImages(updatedMediaList);
    setMediaImageObject({ url: null, name: "", type: "", description: "" });
    setMediaImageEdit(false);
  };

  const onMediaVideoEdit = (index, type, url, name, description) => {
    const updatedMediaList = [...mediaVideos];
    updatedMediaList[index] = {
      type: type,
      url: url,
      name: name,
      description: description,
    };
    setMediaVideos(updatedMediaList);
    setMediaVideoObject({ url: null, name: "", type: "", description: "" });
    setMediaVideoEdit(false);
  };

  const uploadVidHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("video", file);
    setUploadingVid(true);

    if (file) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${serverIp}/api/uploadVid/taskVid`,
          formData,
          config
        );
        setThumbVideo(data);
        setUploadingVid(false);
      } catch (err) {
        console.error(err);
        setUploadingVid(false);
      }
    } else {
      setUploadingVid(false);
    }
  };

  const uploadDocHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("document", file);
    setUploadingDoc(true);

    if (file) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${serverIp}/api/uploadDoc/taskDoc`,
          formData,
          config
        );
        let updatedDocList = [...doc, data];
        console.log(updatedDocList);
        setDoc(updatedDocList);
        setUploadingDoc(false);
      } catch (err) {
        console.error(err);
        setUploadingDoc(false);
      }
    } else {
      setUploadingDoc(false);
    }
  };

  const deleteVidHandler = async (e) => {
    e.preventDefault();
    setThumbVideo("");
  };
  const handleMediaDocDelete = (index) => {
    const updatedMediaList = [...doc];
    updatedMediaList.splice(index, 1);
    setDoc(updatedMediaList);
  };
  const handleMediaImageObjectDelete = (index) => {
    const updatedMediaList = [...mediaImages];
    updatedMediaList.splice(index, 1);
    setMediaImages(updatedMediaList);
  };
  const handleMediaVideoObjectDelete = (index) => {
    const updatedMediaList = [...mediaVideos];
    updatedMediaList.splice(index, 1);
    setMediaVideos(updatedMediaList);
  };
  const handleMediaImageObjectEdit = (index) => {
    setMediaImageEditIndex(index);
    setMediaImageObject(mediaImages[index]);
    setMediaImageEdit(true);
  };
  const handleMediaVideoObjectEdit = (index) => {
    setMediaVideoEditIndex(index);
    setMediaVideoObject(mediaVideos[index]);
    setMediaVideoEdit(true);
  };

  const MAX_MEDIA_COUNT = 5;

  return (
    <Flex direction="column" mt="5px">
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        px={{ sm: "4px", md: "" }}
      >
        <FormContainer>
          <Heading as="h1" mb="8" fontSize="3xl" color="black">
            {t("Add Task")}
          </Heading>

          {loadingUpdate && <Loader />}
          {errorUpdate && <Message type="error">{errorUpdate}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            //onSubmit={submitHandler}
            <form onSubmit={submitHandler}>
              <Flex
                direction={{ sm: "column", md: "row" }}
                flexWrap={{ sm: "nowrap", md: "wrap" }}
                rowGap={{ sm: "10px", md: "15px" }}
                columnGap={{ sm: "0", md: "10px" }}
              >
                {/* TITLE */}
                <FormControl
                  id="title"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="black">{t("Title")}</FormLabel>
                  <Input
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter title")}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>

                {/* Set Result */}
                <FormControl
                  id="result"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="black">{t("Result")}</FormLabel>
                  <Input
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter result")}
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                  />
                </FormControl>

                {/* Thumb IMAGE */}
                <FormControl id="thumbimage" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Cover Image")}</FormLabel>
                  <Flex>
                    <ImageCropper
                      route="uploads/taskImg"
                      setIsShown={setIsShown}
                      setThumbImage={setThumbImage}
                      thumbImage={thumbImage}
                      aspectRatio={1 / 1}
                    />
                  </Flex>
                  {uploadingImg && <Loader />}
                </FormControl>

                {/* task IMAGE */}
                <FormControl id="taskimage" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Task Image")}</FormLabel>
                  <Flex>
                    <ImageCropper
                      route="uploads/taskImg"
                      setIsShown={setIsShown}
                      setThumbImage={setTaskImage}
                      thumbImage={taskImage}
                      aspectRatio={16 / 9}
                    />
                  </Flex>
                  {uploadingImg && <Loader />}
                </FormControl>

                {/* Video */}
                <FormControl id="video" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Video")}</FormLabel>
                  {/* <Flex as={AspectRatio} ratio={16 / 9} display={thumbVideo !== '' ? 'flex' : 'none'} justifyContent='center'>
										<video src={thumbVideo} controls />
									</Flex> */}
                  <Flex gap="3" justifyContent="center">
                    {/* <Input
											display={thumbVideo === '' ? 'flex' : 'none'}
											pt='4px'
											color='black'
											borderColor='black'
											type='file'
											accept='video/*'
											id='video-file'
											onChange={uploadVidHandler}
										/> */}
                    <Input
                      // display={thumbVideo === '' ? 'flex' : 'none'}
                      pt="4px"
                      value={thumbVideo}
                      placeholder="Enter Video Link"
                      color="gray.800"
                      borderColor="black"
                      type="text"
                      onChange={(e) => setThumbVideo(e.target.value)}
                    />
                    {/* <Button
											marginTop='10px'
											colorScheme='red'
											color='black'
											display={thumbVideo === '' ? 'none' : 'flex'}
											onClick={deleteVidHandler}
										>
											{t("Remove")}
										</Button> */}
                  </Flex>
                  {uploadingVid && <Loader />}
                </FormControl>

                <FormControl
                  id="globalScope"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="black">{t("Global Scope")}</FormLabel>
                  {/* <Input
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter title")}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  /> */}
                  <Select
                    color="black"
                    type="text"
                    borderColor="black"
                    value={globalScope}
                    onChange={(e) => setGlobalScope(e.target.value)}
                  >
                    <option value={false}>Assigned</option>
                    <option value={true}>Global</option>
                  </Select>
                </FormControl>

                {/* DESCRIPTION */}
                <FormControl id="description" isRequired>
                  <FormLabel color="black">{t("Description")}</FormLabel>
                  <Input
                    as={Textarea}
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>

                {/* SEQUENCE */}
                <FormControl id="sequence" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Sequnce")}</FormLabel>
                  <Input
                    color="black"
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter Sequence")}
                    value={sequence}
                    onChange={(e) => setSequence(e.target.value)}
                  />
                </FormControl>

                {/* Additional Media Images */}

                <FormControl
                  id="media"
                  width={{ sm: "full", md: "99%" }}
                  color="black"
                >
                  <FormLabel color="black">{t("Additional Images")}</FormLabel>
                  {mediaImages?.map((mediaObject, index) => (
                    <Flex key={index} mt={4}>
                      <Card maxW="sm" bgColor="white">
                        <CardBody>
                          {mediaObject.type === "image" && (
                            <Image
                              src={mediaObject.url}
                              alt={mediaObject.name}
                              borderRadius="lg"
                            />
                          )}
                          {mediaObject.type === "video" && (
                            // <video src={mediaObject.url} controls />
                            <ReactPlayer
                              url={mediaObject.url}
                              controls
                              width="full"
                            />
                          )}
                          <Text color="black" fontSize="xl" fontWeight="bold">
                            {mediaObject.name}
                          </Text>
                          <Text color="black">{mediaObject.description}</Text>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                          <ButtonGroup spacing="2">
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              onClick={() => handleMediaImageObjectEdit(index)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="ghost"
                              colorScheme="blue"
                              onClick={() =>
                                handleMediaImageObjectDelete(index)
                              }
                            >
                              Delete
                            </Button>
                          </ButtonGroup>
                        </CardFooter>
                      </Card>
                    </Flex>
                  ))}
                  {mediaImages?.length < MAX_MEDIA_COUNT && (
                    <Flex mt={4}>
                      <UploadFile
                        media={{ ...mediaImageObject, type: "image" }}
                        index={mediaImageEditIndex}
                        onMediaChange={onMediaImageChange}
                        onMediaSave={onMediaImageSave}
                        onMediaEdit={onMediaImageEdit}
                        mediaEdit={mediaImageEdit}
                        mediaFor="task"
                      />
                    </Flex>
                  )}
                </FormControl>

                {/* Additional Media Videos*/}

                <FormControl
                  id="media"
                  width={{ sm: "full", md: "99%" }}
                  color="black"
                >
                  <FormLabel color="black">{t("Additional Videos")}</FormLabel>
                  {mediaVideos?.map((mediaObject, index) => (
                    <Flex key={index} mt={4}>
                      <Card maxW="sm" bgColor="white">
                        <CardBody>
                          {mediaObject.type === "image" && (
                            <Image
                              src={mediaObject.url}
                              alt={mediaObject.name}
                              borderRadius="lg"
                            />
                          )}
                          {mediaObject.type === "video" && (
                            // <video src={mediaObject.url} controls />
                            <ReactPlayer
                              url={mediaObject.url}
                              controls
                              width="full"
                            />
                          )}
                          <Text color="black" fontSize="xl" fontWeight="bold">
                            {mediaObject.name}
                          </Text>
                          <Text color="black">{mediaObject.description}</Text>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                          <ButtonGroup spacing="2">
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              onClick={() => handleMediaVideoObjectEdit(index)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="ghost"
                              colorScheme="blue"
                              onClick={() =>
                                handleMediaVideoObjectDelete(index)
                              }
                            >
                              Delete
                            </Button>
                          </ButtonGroup>
                        </CardFooter>
                      </Card>
                    </Flex>
                  ))}
                  {mediaVideos?.length < MAX_MEDIA_COUNT && (
                    <Flex mt={4}>
                      <UploadFile
                        media={{ ...mediaVideoObject, type: "video" }}
                        index={mediaVideoEditIndex}
                        onMediaChange={onMediaVideoChange}
                        onMediaSave={onMediaVideoSave}
                        onMediaEdit={onMediaVideoEdit}
                        mediaEdit={mediaVideoEdit}
                        mediaFor="task"
                      />
                    </Flex>
                  )}
                </FormControl>

                {/* Doc */}
                <FormControl id="Document">
                  <FormLabel color="black">{t("Document")}</FormLabel>

                  {doc.map((docObj, index) => (
                    <Flex key={index} py="5" direction="column">
                      <Text fontWeight="bold">Attached Document</Text>
                      <Link href={docObj} target="_blank">
                        <Image
                          src={
                            docObj.split(".").pop() === "pdf"
                              ? "../../pdfimg.png"
                              : "../../excelimg.png"
                          }
                          alt="PDF/EXCEL"
                          h="140px"
                          w="140px"
                        />
                      </Link>

                      <Button
                        ml="17px"
                        marginTop="10px"
                        colorScheme="red"
                        w="100px"
                        color="white"
                        display={doc === "" ? "none" : "flex"}
                        onClick={() => handleMediaDocDelete(index)}
                      >
                        {t("Remove")}
                      </Button>
                    </Flex>
                  ))}

                  {doc.length < MAX_MEDIA_COUNT && (
                    <Flex mt={4}>
                      <Input
                        pt="4px"
                        color="black"
                        borderColor="black"
                        type="file"
                        id="doc-file"
                        onChange={uploadDocHandler}
                      />
                    </Flex>
                  )}
                  {uploadingDoc && <Loader />}
                </FormControl>

                <Flex gap="5" mt="6">
                  <Button
                    colorScheme="teal"
                    color="white"
                    type="submit"
                    isLoading={loading}
                  >
                    {t("Done")}
                  </Button>
                  <AlertDialogExample />
                </Flex>
              </Flex>
            </form>
          )}
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default TaskEditScreen;
