import { Flex, Icon, Button, Heading, Image, Text, Link, AspectRatio } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IoPencilSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { supportDetails as supDetails } from '../actions/infoActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const SupportScreen = () => {
	const dispatch = useDispatch();

	const supportDetails = useSelector((state) => state.supportDetails);
	const { loading, error, support } = supportDetails;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(supDetails());
	}, [dispatch]);

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex bg='#dfcaa4' py='2' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<>
							{support.support.map((obj) => (
								<Button as={RouterLink} to={`/support/${obj._id}/edit`} bgColor='#ab846a' color='black'>
									<Icon as={IoPencilSharp} boxSize='5' /> {t("Edit Support")}
								</Button>
							))}
						</>
					)}
				</Flex>

				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Flex pt='5px' direction={{ sm: 'column', md: 'row' }} gap='2' flexWrap='wrap' px={{ sm: '5px', md: '70px' }}>
						<Flex direction='column' gap='2'>
							{support.support.map((obj) => (
								<Flex direction='column' w='450px' gap='3'>
									<Heading size='xl'>{obj.title}</Heading>
									<AspectRatio ratio={16 / 9}>
										<Image src={obj.image} />
									</AspectRatio>
									<Text>{obj.description}</Text>
									<Text>{t("Call Me")} {": +49"} {obj.callUs}</Text>
									<Text>{t("Mail Me")} {":"} {obj.mailUs}</Text>
									<Link href={obj.appointmentLink} target='_blank' pb='15px'><Button bg='#ab846a'>{t("Book Appointment")}</Button></Link>
								</Flex>
							))}
						</Flex>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default SupportScreen;