import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Text,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoTrashBinSharp,
	IoAddCircleOutline,
} from 'react-icons/io5';
import { MdOutlineAssignmentInd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listTasks, deleteTasks } from '../actions/tasksActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const TasksListScreen = () => {
	const dispatch = useDispatch();

	const tasksList = useSelector((state) => state.tasksList);
	const { loading, error, tasks } = tasksList;

	const tasksDelete = useSelector((state) => state.tasksDelete);
	const {
		success: successDelete,
	} = tasksDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listTasks());
	}, [dispatch, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteTasks(id));
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: 'full', md: 'full' }}>
				<Flex py='2' bg='#dfcaa4' px={{ sm: '5px', lg: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Button bgColor='#ab846a' color='black' as={RouterLink} to={'/tasks/add'}>
						<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Add Task")}
					</Button>
				</Flex>
				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{ sm: 'full', md: 'full' }}>
						<Table variant='striped' colorScheme='gray' size='sm'>
							<Thead>
								<Tr>
									<Th>{t("NAME")}</Th>
									<Th>
										<Flex justifyContent='flex-end' mr={{ sm: '-1', lg: '9' }} gap={{ sm: '5px', md: '53px' }}>
											<Text>{t("DELETE")}</Text>
											<Text>{t("ASSIGN")}</Text>
										</Flex>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{tasks.task.map((obj) => (
									<Tr key={obj._id}>
										<Td>
											<Flex as={RouterLink} to={`/tasks/${obj._id}?progId=null`}>
												{obj.title}
											</Flex>
										</Td>

										<Td>
											<Flex justifyContent='flex-end' alignItems='center' gap='4'>
												<Button size={{ sm: 'sm', md: 'md' }} colorScheme='red' onClick={() => deleteHandler(obj._id)} >
													<Icon as={IoTrashBinSharp} />
												</Button>
												<Button
													as={RouterLink}
													to={`/task/${obj._id}/assign`}
													size={{ sm: 'sm', lg: 'md' }}
													colorScheme='teal'
													color='white'>
													<Text display={{ base: 'none', md: 'block' }}>{t("Assign Task")}</Text>
													<Icon display={{ base: 'block', md: 'none' }} as={MdOutlineAssignmentInd} />
												</Button>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
			</Flex>
		</Flex>
	);
};

export default TasksListScreen;
