import {
  TOKEN_UPDATE_REQUEST,
  TOKEN_UPDATE_SUCCESS,
  TOKEN_UPDATE_FAIL,
} from "../constants/fcmpnConstants";

export const tokenUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOKEN_UPDATE_REQUEST:
      return { loading: true };
    case TOKEN_UPDATE_SUCCESS:
      return { loading: false, success: true, token: action.payload };
    case TOKEN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
