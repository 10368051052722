import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Image,
	AspectRatio,
	Textarea,
	Card,
	CardBody,
	CardFooter,
	Text,
	Divider,
	ButtonGroup,
	Icon
} from '@chakra-ui/react';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import serverIp from '../config/config';
import FormContainer from '../components/FormContainer';
import { createEvents, listAllProgEvents, copyEvents } from '../actions/eventsActions';
import { EVENTS_CREATE_RESET } from '../constants/EventsConstants';
import UploadFile from '../components/UploadFile';
import ImageCropper from '../components/ImageCropper';
import AlertDialogExample from '../components/AlertDialog';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { copyTasks, listAllProgTasks } from '../actions/tasksActions';

const TaskCopyScreen = () => {

	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();


	const { t } = useTranslation();

	const allProgTasksList = useSelector((state) => state.allProgTasksList);
	const { loading, tasks, error } = allProgTasksList;

	const tasksCopy = useSelector((state) => state.tasksCopy);
	const { loading: laodingCopy, error: errorCopy, success, tasks: copiedTasks } = tasksCopy;

	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		dispatch(listAllProgTasks());
	}, [dispatch])

	useEffect(() => {
		if (success) {
			navigate(`/program/${id}/tasks`)
		}
	}, [id, success]);

	const handleItemClick = (id) => {
		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter(item => item !== id));
		} else {
			setSelectedItems([...selectedItems, id]);
		}
	};

	const handleTaskCopy = () => {
		dispatch(copyTasks({ taskIdsToCopy: selectedItems, newProgramId: id }))
	}

	const MenuItem = ({ id, imageSrc, itemName, itemDescription }) => {
		const isSelected = selectedItems.includes(id);

		return (
			<a
				className={`flex flex-col pb-4 items-center justify-between border border-gray-200 rounded-lg shadow md:max-w-xl cursor-pointer ${isSelected ? 'bg-orange-200 hover:bg-orange-200' : 'bg-white hover:bg-gray-100'}`}
				onClick={() => handleItemClick(id)}
			>
				<div className='flex flex-col items-center'>
					<img className="object-cover object-center w-40 rounded-lg h-96 md:h-40 md:rounded-none md:rounded-lg" src={imageSrc} alt="" />
					<div className="flex flex-col max-h-1/2 overflow-hidden justify-between p-4 leading-normal">
						<h5 className="mb-2 text-xl w-40 font-bold tracking-tight text-gray-900 dark:text-white">{itemName}</h5>
						<p className="mb-3 font-normal w-40 text-gray-700 dark:text-gray-400">{itemDescription}</p>
					</div>
				</div>

				<div className="flex flex-col gap-2">
					<BsFillHandThumbsUpFill className={`self-center text-2xl text-blue-600 ${isSelected ? "flex animate-jump-in" : "hidden"}`} />
					<button
						className={`py-2 px-4 rounded-md text-white font-semibold ${isSelected ? 'bg-red-500' : 'bg-green-500'}`}
					>
						{isSelected ? 'Abwählen' : 'Auswählen'}
					</button>
				</div>
			</a>
		);
	};

	return (
		<Flex direction='column'>
			<Flex py='2' bg='#dfcaa4' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
				<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to={`/program/${id}/tasks`} color='white' alignItems='center' gap='2'>
					<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
					{t("Back")}
				</Flex>
				<Button color='black' bg='#ab846a' onClick={handleTaskCopy}>
					{t("Copy")}
				</Button>
			</Flex>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Flex flexWrap='wrap' flexDirection={{ sm: 'column', md: 'row' }} p='4' gap='4' px='12'>
					{tasks.map((task, index) => (
						<MenuItem
							key={index}
							id={task._id}
							imageSrc={task.thumbImage}
							itemName={task.title}
							itemDescription={task.description}
						/>
					))}
				</Flex>
			)}
		</Flex>
	)
}

export default TaskCopyScreen;