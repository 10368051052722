import { Flex } from '@chakra-ui/react';

const FormContainer = ({ children }) => {
	const param = window.location.pathname;
	return (
		<Flex mb='10px' border='1px' borderTop={param === '/' ? 'none' : '1px'} borderColor='black' boxShadow='lg' roundedTop={param === '/'?'none': 'md'} roundedBottom='md'  w={param === '/#/'? {sm:'380px',md:'500px'} : {sm:'380px',md:'full'}} >
		<Flex
			roundedBottom='md'
			roundedTop={param === '/'?'none': 'md'}
			bgColor='#dfcaa4'
			opacity='0.9'
			p='6'
			h={param === '/home' ? '450px' : 'full'}
			display='block'
			w='full'
			>
			<Flex
			direction='column'
			w='full'
			>
			{children}
			</Flex>
		</Flex>
		</Flex>
	);
};

export default FormContainer;