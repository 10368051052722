import axios from "axios";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  AspectRatio,
  Image,
  Input,
  Select,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import serverIp from "../config/config";
import Loader from "./Loader";

const UploadFile = (props) => {
  let index = props.index;
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [uploading, setUploading] = useState(false);
  const [fileDescription, setFileDescription] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("md");

  const handleSizeClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };

  const sizes = ["xl"];

  useEffect(() => {
    props.mediaEdit && onOpen();
    setFile(props.media.url);
    setFileTitle(props.media.name);
    setFileType(props.media.type);
    setFileDescription(props.media.description);
  }, [props.mediaEdit, props.media, onOpen]);

  const handleFileChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(`${fileType}`, file);
    setUploading(true);
    const path =
      fileType === "image"
        ? `uploads/${
            props.mediaFor === "program"
              ? "progImg"
              : props.mediaFor === "event"
              ? "eventImg"
              : props.mediaFor === "task"
              ? "taskImg"
              : ""
          }`
        : `uploadVid/${
            props.mediaFor === "program"
              ? "progVid"
              : props.mediaFor === "event"
              ? "eventVid"
              : props.mediaFor === "task"
              ? "taskVid"
              : ""
          }`;
    if (file) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${serverIp}/api/${path}`,
          formData,
          config
        );
        setFile(data);
        setUploading(false);
      } catch (err) {
        console.error(err);
        setUploading(false);
      }
    } else {
      setUploading(false);
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleFileTitleChange = (event) => {
    setFileTitle(event.target.value);
  };

  const handleFileDescriptionChange = (event) => {
    setFileDescription(event.target.value);
  };

  const handleDelete = () => {
    setFile(null);
  };

  const mediaSaveHandler = () => {
    props.onMediaSave(index, fileType, file, fileTitle, fileDescription);
    onClose();
  };

  const mediaEditHandler = () => {
    props.onMediaEdit(index, fileType, file, fileTitle, fileDescription);
    onClose();
  };

  const handleCancle = () => {
    props.onMediaSave(index, fileType, file, fileTitle, fileDescription);
    onClose();
  };

  return (
    <Box>
      {sizes.map((size) => (
        <Button
          colorScheme="teal"
          onClick={() => handleSizeClick(size)}
          key={size}
          m={4}
        >{`Add Media`}</Button>
      ))}

      <Modal size={size} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton
            onClick={onClose}
            disabled={file === null || file === "" ? false : true}
          />
          <ModalBody>
            <FormControl>
              <FormLabel>Select file type:</FormLabel>
              <Select
                sx={{
                  option: {
                    bg: "white",
                    color: "black",
                  },
                }}
                value={fileType}
                onChange={handleFileTypeChange}
                isDisabled={!!fileType}
              >
                <option value="">Select type</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
              </Select>
            </FormControl>

            <FormControl id="file">
              <FormLabel color="white">{"Upload File"}</FormLabel>
              <Flex
                as={AspectRatio}
                ratio={16 / 9}
                display={
                  file === "" || fileType !== "image" || file === null
                    ? "none"
                    : "flex"
                }
                justifyContent="center"
              >
                <Image src={file} />
              </Flex>
              {/* <Flex as={AspectRatio} ratio={16 / 9} display={file === '' || fileType!=='video' || file === null ? 'none' : 'flex'} justifyContent='center'>
                                <video src={file} controls />
                            </Flex> */}
              <Flex justifyContent="center">
                <Input
                  //   display={file === "" || file === null ? "flex" : "none"}
                  //   disabled={!fileType}
                  pt="4px"
                  value={fileType === "image" ? "" : file}
                  color="gray.800"
                  borderColor="#f6ebd9"
                  type={fileType === "image" ? "file" : "text"}
                  accept={`${fileType === "image" ? fileType : ""}/*`}
                  id="image-file"
                  onChange={
                    fileType === "image"
                      ? handleFileChange
                      : (e) => setFile(e.target.value)
                  }
                />
                {/* <Button
                  marginTop="10px"
                  colorScheme="red"
                  color="white"
                  display={file === "" || file === null ? "none" : "flex"}
                  onClick={handleDelete}
                >
                  {"Remove"}
                </Button> */}
              </Flex>
              {uploading && <Loader />}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>File Title:</FormLabel>
              <Input
                type="text"
                value={fileTitle}
                onChange={handleFileTitleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>File Description:</FormLabel>
              <Textarea
                value={fileDescription}
                onChange={handleFileDescriptionChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={mediaEditHandler}
              display={props.mediaEdit ? "flex" : "none"}
            >
              Save
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={mediaSaveHandler}
              disabled={file === null || file === "" ? true : false}
              display={props.mediaEdit ? "none" : "flex"}
            >
              Add
            </Button>
            <Button
              onClick={handleCancle}
              disabled={file === null || file === "" ? false : true}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UploadFile;
