import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUserDetails, updateUserProfile } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';
import {
	USER_DETAILS_RESET,
	USER_UPDATE_PROFILE_RESET,
} from '../constants/userConstants';

const ProfileScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
	const { success } = userUpdateProfile;

	const { t } = useTranslation();

	useEffect(() => {
		if (!userInfo) {
			navigate('/');
		} else {
			if (!user.user.name) {
				dispatch(getUserDetails());
			} else {
				setName(user.user.name);
				setEmail(user.user.email);
			}
		}
	}, [userInfo, navigate, user, dispatch, success]);

	const submitHandler = (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setMessage('Passwords do not match');
		} else {
			dispatch(updateUserProfile({ id: user.user._id, name, email, password }));
			dispatch({ type: USER_UPDATE_PROFILE_RESET });
			dispatch({ type: USER_DETAILS_RESET });
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex direction='column'>
				<Flex py='4' bg='#dfcaa4' px={{ sm: '5px', lg: '40px' }} alignItems='center' justifyContent='space-between'>
					<Heading fontSize='xl' as={RouterLink} to='/home' color='white'>
						{t("Home")}
					</Heading>
					<Heading fontSize='xl' color='white'>
						{t("Admin Profile")}
					</Heading>
				</Flex>
				<Flex padding='4px'>
					<FormContainer>
						<Heading as='h1' mb='6' fontSize='3xl' color='white'>
							{t("User Profile")}
						</Heading>

						{error && <Message type='error'>{error}</Message>}
						{message && <Message type='error'>{message}</Message>}
						{success && <Message type='info'>{t("Profile Updated")}</Message>}

						<form onSubmit={submitHandler}>
							<Flex direction='row' flexWrap='wrap' rowGap={{ sm: '10px', md: '15px' }} columnGap={{ sm: '0', md: '10px' }}>
								<FormControl id='name' width={{ sm: 'full', md: '49%' }}>
									<FormLabel htmlFor='name' color='white'>{t("Your Name")}</FormLabel>
									<Input
										id='name'
										type='text'
										borderColor='white'
										color='white'
										placeholder={t("Your full name")}
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</FormControl>


								<FormControl id='email' width={{ sm: 'full', md: '49%' }}>
									<FormLabel htmlFor='email' color='white'>{t("Email address")}</FormLabel>
									<Input
										id='email'
										color='white'
										borderColor='white'
										type='email'
										placeholder={t("username@domain.com")}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormControl>

								<FormControl id='password' width={{ sm: 'full', md: '49%' }}>
									<FormLabel htmlFor='password' color='white'>{t("Password")}</FormLabel>
									<Input
										id='password'
										color='white'
										borderColor='white'
										type='password'
										placeholder='************'
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</FormControl>

								<FormControl id='confirmPassword' width={{ sm: 'full', md: '49%' }}>
									<FormLabel htmlFor='confirmPassword' color='white'>{t("Confirm Password")}</FormLabel>
									<Input
										id='confirmPassword'
										type='password'
										borderColor='white'
										color='white'
										placeholder='************'
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
								</FormControl>

								<Button colorScheme='teal' type='submit' color='white' mt='4' isLoading={loading}>
									{t("Update")}
								</Button>
							</Flex>
						</form>
					</FormContainer>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ProfileScreen;