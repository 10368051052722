import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { getListUserDetails, updateUser } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_UPDATE_RESET } from "../constants/userConstants";
import AlertDialogExample from "../components/AlertDialog";

const UserEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();

  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");

  const userListDetails = useSelector((state) => state.userListDetails);
  const { loading, error, user } = userListDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const { t } = useTranslation();

  function convertDateFormat(dateString) {
    console.log(dateString);
    // Split the input string by "-"
    const dateObject = new Date(dateString);
    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
    const year = dateObject.getUTCFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate;
  }

  function formatDate(isoString) {
    const date = new Date(isoString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getUTCFullYear();

    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      dispatch(getListUserDetails(userId));
      navigate("/usersList");
    } else {
      if (!user?.user?.name || user?.user?._id !== userId) {
        dispatch(getListUserDetails(userId));
      } else {
        setName(user.user.name);
        setEmail(user.user.email);
        setOrgName(user.user.orgName);
        setPosition(user.user.position);
        // console.log(user.user.dob);
        setDob(formatDate(user.user.dob));
      }
    }
  }, [user, dispatch, userId, successUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(dob);
    dispatch(updateUser({ _id: userId, name, orgName, position, email, dob }));
  };

  const handleDateChange = (e) => {
    const dateObject = new Date(e);
    const formattedDate = dateObject.toLocaleDateString("de", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    // const inputDate = e.target.value; // Get the raw date value (YYYY-MM-DD format)
    console.log(formattedDate);
    // const parts = inputDate.split("-"); // Split the date into parts
    // const formattedDate = `${parts[2]}.${parts[1]}.${parts[0]}`; // Rearrange the parts in DD.MM.YYYY format
    setDob(formattedDate); // Update the state with the formatted date
  };

  return (
    <Flex direction="column" mt="5px">
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        px={{ sm: "4px", md: "" }}
      >
        <FormContainer>
          <Heading as="h1" mb="8" fontSize="3xl" color="black">
            {t("Edit User")}
          </Heading>

          {loadingUpdate && <Loader />}
          {errorUpdate && <Message type="error">{errorUpdate}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            <form onSubmit={submitHandler}>
              <Flex
                direction={{ sm: "column", md: "row" }}
                flexWrap={{ sm: "nowrap", md: "wrap" }}
                rowGap={{ sm: "10px", md: "15px" }}
                columnGap={{ sm: "0", md: "10px" }}
              >
                <FormControl id="name" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Name")}</FormLabel>
                  <Input
                    type="text"
                    borderColor="black"
                    placeholder={t("Enter name")}
                    color="black"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>

                <FormControl id="orgname" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Organization Name")}</FormLabel>
                  <Input
                    type="text"
                    color="black"
                    borderColor="black"
                    placeholder={t("Enter Organization Name")}
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                </FormControl>

                <FormControl id="position" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Position")}</FormLabel>
                  <Input
                    type="text"
                    color="black"
                    borderColor="black"
                    placeholder={t("Enter Position")}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </FormControl>

                <FormControl id="email" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="black">{t("Email Address")}</FormLabel>
                  <Input
                    type="text"
                    color="black"
                    borderColor="black"
                    placeholder={t("Enter email address")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <FormControl id="dob" width={{ sm: "full", md: "99%" }}>
                  <FormLabel color="black">{t("Date Of Birth")}</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    color="black"
                    borderColor="black"
                    disabled
                    value={dob}
                  />
                </FormControl>

                <Flex gap="5" mt="6">
                  <Button
                    colorScheme="teal"
                    color="white"
                    type="submit"
                    isLoading={loading}
                  >
                    {t("Update")}
                  </Button>
                  <AlertDialogExample />
                </Flex>
              </Flex>
            </form>
          )}
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default UserEditScreen;
