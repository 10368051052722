import { Flex, Text, Image, Link, Heading, UnorderedList, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Flex w='100%' direction='row' justifyContent='space-between' alignItems='center' padding='3px' bg='#dfcaa4' as='footer'> 
		{/* linear-gradient(gray, #5d5959) */}
			<Flex direction='column'>
				<Heading fontSize='15px' color='black'>{t("MENU")}</Heading>
				<UnorderedList>
					<ListItem color='black' fontSize='15px'><Link href='https://katrintrittner.de/impressum' target='_blank'>{t("Imprint")}</Link></ListItem>
					<ListItem color='black' fontSize='14px'><Link href='https://katrintrittner.de/datenschutzerklaerung' target='_blank'>{t("Data Protection")}</Link></ListItem>
				</UnorderedList>
			</Flex>

			<Flex direction={{sm:'column',md:'row'}} justifyContent='center' alignItems='center'>
				<Text color='black' fontSize='sm' fontWeight='bold' py={{ sm: '1', md: '5' }}>
					{t("Product By.")}
				</Text>
				<Link href='https://soradisdigital.com/germany/' target='_blank'>
					<Image src='sgdl.png' h={{ sm: '70px', lg: '60px' }} w={{ sm: '90px', lg: '80px' }} />
				</Link>
			</Flex>

			<Flex>
				<Text fontWeight='bold' color='black'><Link href='PrivacyPolicy.html' target='_blank'>Privacy Policy</Link></Text>
			</Flex>
		</Flex>
	);
};

export default Footer;