import {
  Image,
  Heading,
  Icon,
  Text,
  Button,
  Flex,
  Box,
} from "@chakra-ui/react";
import { TbTrash } from "react-icons/tb";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { deletePrograms } from "../actions/programsActions";

const ProgramsListCard = (program) => {
  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deletePrograms(id));
    }
  };

  return (
    <div className="flex items-center h-32 p-2 bg-white rounded-lg shadow-lg hover:scale-105">
      <img
        className="w-28 h-28 object-cover object-center rounded-md border-2 border-solid border-black"
        src={program.program.thumbImage}
      />
      <div className="flex flex-col w-full pl-2">
        <RouterLink to={`/programs/${program.program._id}`} className="">
          <h3 className="line-clamp-1 font-semibold">{program.program.name}</h3>
          <p className="line-clamp-3 text-sm">{program.program.description}</p>
        </RouterLink>
        <div className="flex w-full justify-between">
          <h3 className="font-semibold">
            {program.program.price?.toFixed(2).replace(".", ",")} €
          </h3>
          <button onClick={() => deleteHandler(program.program._id)}>
            <Icon
              as={TbTrash}
              boxSize={{ sm: "5", lg: "6" }}
              fontSize="xl"
              fontWeight="bold"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramsListCard;
