import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBD7fGdXXrZe-sIc0oT1zhKJcK41syEorA",
  authDomain: "yoga-coach-app.firebaseapp.com",
  projectId: "yoga-coach-app",
  storageBucket: "yoga-coach-app.appspot.com",
  messagingSenderId: "941854171383",
  appId: "1:941854171383:web:5774951706d792e741f3f2",
  measurementId: "G-V24S69ZW19",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
