import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Table,
  Image,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  IoAddCircleOutline,
  IoCheckmarkCircleSharp,
  IoCloseCircleSharp,
} from "react-icons/io5";
import { BsReply } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { listDoubtUsers } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { deleteCoupon, listCoupons } from "../actions/couponActions";
import { BiEdit } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";

const CouponsListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const couponList = useSelector((state) => state.couponList);
  const { loading, error, coupons } = couponList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const couponDelete = useSelector((state) => state.couponDelete);
  const { success } = couponDelete;

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo && userInfo.isCoach) {
      dispatch(listCoupons());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, success]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteCoupon(id));
    }
  };

  return (
    <Flex direction="column" w="full">
      <Flex h="full" direction="column" w={{ sm: "390px", md: "full" }}>
        <Flex
          py="4"
          bg="#dfcaa4"
          px={{ sm: "5px", md: "40px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            fontSize="xl"
            fontWeight="bold"
            as={RouterLink}
            to="/home"
            color="white"
            alignItems="center"
            gap="2"
          >
            <Icon as={FaRegArrowAltCircleLeft} boxSize="6" color="white" />
            {t("Home")}
          </Flex>
          <Button
            color="black"
            bg="#ab846a"
            as={RouterLink}
            to={"/coupon?type=add"}
          >
            <Icon as={IoAddCircleOutline} boxSize="5" /> {t("Add Coupon")}
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Box
            bgColor="white"
            rounded="lg"
            shadow="lg"
            px="5"
            py="5"
            w={{ sm: "390px", md: "full" }}
          >
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>{t("Code")}</Th>
                  <Th>{t("Type")}</Th>
                  <Th>{t("Discount")}</Th>
                  <Th>{t("Start")}</Th>
                  <Th>{t("End")}</Th>
                  <Th>{t("Is Active")}</Th>
                  <Th>
                    <Flex
                      justifyContent="flex-end"
                      mr={{ sm: "", lg: "9" }}
                      gap="24px"
                    >
                      <Text>{t("Edit")}</Text>
                    </Flex>
                  </Th>
                  <Th>
                    <Flex
                      justifyContent="flex-end"
                      mr={{ sm: "", lg: "9" }}
                      gap="24px"
                    >
                      <Text>{t("Delete")}</Text>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {coupons.map((obj) => (
                  <Tr>
                    <Td>{obj.code}</Td>
                    <Td>{obj.discountType}</Td>
                    <Td>{obj.discountValue}</Td>
                    <Td>
                      {`${
                        new Date(obj.validFrom)
                          .toLocaleString("de-DE", {
                            hour12: false,
                          })
                          .split(",")[0]
                      }`}
                    </Td>
                    <Td>{`${
                      new Date(obj.validUntil)
                        .toLocaleString("de-DE", {
                          hour12: false,
                        })
                        .split(",")[0]
                    }`}</Td>
                    <Td>
                      {obj.isActive ? (
                        <Flex pl="3.5">
                          <Icon
                            as={IoCheckmarkCircleSharp}
                            color="green.600"
                            w="8"
                            h="8"
                          />
                        </Flex>
                      ) : (
                        <Flex pl="3.5">
                          <Icon
                            as={IoCloseCircleSharp}
                            color="red.600"
                            w="8"
                            h="8"
                          />
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Button
                          mr="4"
                          as={RouterLink}
                          to={`/coupon/${obj._id}`}
                          color="white"
                          colorScheme="teal"
                        >
                          <Text display={{ base: "none", md: "block" }}>
                            {t("Edit")}
                          </Text>
                          <Icon
                            display={{ base: "block", md: "none" }}
                            as={BiEdit}
                            color="white"
                            size="sm"
                          />
                        </Button>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Button
                          mr="4"
                          onClick={() => handleDelete(obj._id)}
                          color="white"
                          colorScheme="red"
                        >
                          <Text display={{ base: "none", md: "block" }}>
                            {t("Delete")}
                          </Text>
                          <Icon
                            display={{ base: "block", md: "none" }}
                            as={TbTrash}
                            color="white"
                            size="sm"
                          />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default CouponsListScreen;
