import { Flex } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { React, useEffect, useState } from "react";

import Footer from "./components/Footer";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import ProgramsListScreen from "./screens/ProgramsListScreen";
import EventsListScreen from "./screens/EventsListScreen";
import EventScreen from "./screens/EventsScreen";
import EventAddScreen from "./screens/EventAddScreen";
import EventEditScreen from "./screens/EventEditScreen";
import DoubtListScreen from "./screens/DoubtListScreen";
import ProgramsScreen from "./screens/ProgramsScreen";
import ProgramAddScreen from "./screens/ProgramAddScreen";
import ProgramEditScreen from "./screens/ProgramEditScreen";
import TasksListScreen from "./screens/TasksListScreen";
import TaskScreen from "./screens/TasksScreen";
import TaskAddScreen from "./screens/TaskAddScreen";
import TaskEditScreen from "./screens/TaskEditScreen";
import UserListScreen from "./screens/UserListScreen";
import OrderListScreen from "./screens/OrderListScreen";
import DoubtScreen from "./screens/DoubtScreen";
import AssignTaskScreen from "./screens/AssignTasks";
import UserTasksListScreen from "./screens/UserTaskListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import FeedBackProgListScreen from "./screens/FeedbackProgListScreen";
import FeedbackUserListScreen from "./screens/FeedbackUserListScreen";
import FeedBackScreen from "./screens/FeedbackScreen";
import SupportScreen from "./screens/SupportScreen";
import AboutScreen from "./screens/AboutUsScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AboutUsEditScreen from "./screens/AboutUsEditScreen";
import SupportEditScreen from "./screens/SupportEditScreen";
import ProgEventsListScreen from "./screens/ProgEventScreen";
import ProgTasksListScreen from "./screens/ProgTaskScreen";
import Messenger from "./screens/messenger/Messenger";
import ImageCropper from "./components/ImageCropper";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import EventCopyScreen from "./screens/EventCopyScreen";
import TaskCopyScreen from "./screens/TaskCopyScreen";
import UserDeleteScreen from "./screens/UserDeleteScreen";

import {
  updateToken,
} from "./actions/fcmpnActions";
import CouponsListScreen from "./screens/CouponstListScreen";
import CouponForm from "./screens/CouponForm";

const App = () => {
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const tokenUpdate = useSelector((state) => state.tokenUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success } = tokenUpdate;

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BOPg9TdsjXdeB-_WjVD8FkcO-9WInUbP2HxOhjk_0QDUZm6w--zOINt5wZu9cXRwEw7di7UIHZxGFWZ4Kn5xWUk",
      });
      console.log(token);
      setToken(fcmToken);
      console.log(fcmToken);

      // Create or update the token
      if (fcmToken !== userInfo.messagingToken) {
        dispatch(updateToken(fcmToken));
      }
    } else if (permission === "denied") {
      alert("You denied the notification");
    }
  };

  useEffect(() => {
    if (userInfo) {
      requestPermission();
    }
  }, [dispatch, userInfo]);

  return (
    <HashRouter>
      <Flex
        bg="#fffdf0"
        w="100%"
        direction="column"
        minH="100vh"
        height="max-content"
        justifyContent="space-between"
        as="main"
      >
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route
            path="/home"
            element={userInfo ? <HomeScreen /> : <Navigate to="/" />}
          />
          <Route path="/programs" element={<ProgramsListScreen />} />
          <Route path="/events" element={<EventsListScreen />} />
          <Route path="/deleteaccount" element={<UserDeleteScreen />} />

          <Route path="/tasks" element={<TasksListScreen />} />
          <Route path="/feedProg" element={<FeedBackProgListScreen />} />
          <Route path="/doubtList" element={<DoubtListScreen />} />
          <Route path="/coupon" element={<CouponForm />} />
          <Route path="/coupon/:id" element={<CouponForm />} />
          <Route path="/coupons" element={<CouponsListScreen />} />
          <Route path="/support" element={<SupportScreen />} />
          <Route path="/aboutus" element={<AboutScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/usersList" element={<UserListScreen />} />
          <Route path="/messenger" element={<Messenger />} />
          <Route path="/imageCrop" element={<ImageCropper />} />
          <Route path="/orderList" element={<OrderListScreen />} />
          <Route path="/programs/add" element={<ProgramAddScreen />} />
          <Route path="/tasks/add" element={<TaskAddScreen />} />
          <Route path="/events/add" element={<EventAddScreen />} />
          <Route path="/events/:id" element={<EventScreen />} />
          <Route path="/programs/:id" element={<ProgramsScreen />} />
          <Route path="/events/:id/edit" element={<EventEditScreen />} />
          <Route path="/programs/:id/events/add" element={<EventAddScreen />} />
          <Route
            path="/programs/:id/events/copy"
            element={<EventCopyScreen />}
          />
          <Route path="/programs/:id/edit" element={<ProgramEditScreen />} />
          <Route path="/task/:id/assign" element={<AssignTaskScreen />} />
          <Route path="/tasks/:id" element={<TaskScreen />} />
          <Route path="/programs/:id/tasks/add" element={<TaskAddScreen />} />
          <Route path="/programs/:id/tasks/copy" element={<TaskCopyScreen />} />
          <Route path="/tasks/:id/edit" element={<TaskEditScreen />} />
          <Route path="/doubt/:id" element={<DoubtScreen />} />
          <Route path="/users/:id/tasks" element={<UserTasksListScreen />} />
          <Route path="/users/:id/edit" element={<UserEditScreen />} />
          <Route
            path="/feedProg/:id/users"
            element={<FeedbackUserListScreen />}
          />
          <Route path="/feedback/:id" element={<FeedBackScreen />} />
          <Route path="/aboutus/:id/edit" element={<AboutUsEditScreen />} />
          <Route path="/support/:id/edit" element={<SupportEditScreen />} />
          <Route path="/program/:id/tasks" element={<ProgTasksListScreen />} />
          <Route
            path="/program/:id/events"
            element={<ProgEventsListScreen />}
          />
        </Routes>
        <Flex>
          <Footer />
        </Flex>
      </Flex>
    </HashRouter>
  );
};

export default App;
