import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Center, Flex, Heading, Icon, Textarea } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import Message from "../components/Message";
import Loader from "../components/Loader";
import { listDoubtDetails } from "../actions/doubtsActions";
import serverIp from "../config/config";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const DoubtScreen = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [reply, setReply] = useState('')

    const doubtsDetails = useSelector((state) => state.doubtsDetails);
    const { loading, error, doubts } = doubtsDetails;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(listDoubtDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (doubts && doubts.doubt._id) {
            setReply(doubts.doubt.reply);
        }
    }, [doubts])

    const replyHandler = async (e) => {
        e.preventDefault();
        let doubtId = id;
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                },
            };

            const { data } = await axios.post(`${serverIp}/api/coach/programs/tasks/user/doubt`, { reply, doubtId }, config);
            setReply(data);
            navigate('/doubtList');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Flex direction='column' w='full'>
                <Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
                    <Flex bg='#dfcaa4' py='4' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
                        <Flex fontSize='xl' fontWeight='bold' color='white' as={RouterLink} to='/doubtList' alignItems='center' gap='2'>
                            <Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
                            {doubts.userName}{t("'s Question")}
                        </Flex>
                    </Flex>
                    {loading ? (
                        <Loader />
                    ) : error ? (
                        <Message type='error'>{error}</Message>
                    ) : (
                        <Flex pt='5px' direction={{ sm: 'column', md: 'column' }} gap='2' flexWrap='wrap' px={{ sm: '5px', md: '70px' }}>
                            <Flex justifyContent='space-between'>
                                <Heading
                                    as='h6'
                                    my='5'
                                    fontSize='sm'
                                    fontWeight='bold'
                                    color='blue.600'>
                                    {`${new Date(doubts.doubt.createdAt).toLocaleString('de-DE', { hour12: false })}`}
                                </Heading>
                            </Flex>

                            <Flex direction='column' borderBottom='2px' borderBottomColor='gray.300' gap='2'>
                                <Heading size='sm' color='gray'>
                                    {t("Selected Task")}
                                </Heading>
                                <Heading size='sm'>
                                    {doubts.doubt.name}
                                </Heading>
                            </Flex>

                            <Flex direction='column' borderBottom='2px' borderBottomColor='gray.300' gap='2'>
                                <Heading size='sm' color='gray'>
                                    {t("Description")}
                                </Heading>
                                <Heading size='sm'>
                                    {doubts.doubt.description}
                                </Heading>
                            </Flex>

                            <Flex direction='column' borderBottom='2px' borderBottomColor='gray.300' gap='2'>
                                <Heading size='sm' color='gray'>
                                    {t("Reply")}
                                </Heading>
                                <Textarea
                                    isRequired
                                    type='text'
                                    placeholder={t("Reply to doubt")}
                                    value={reply}
                                    onChange={(e) => setReply(e.target.value)}
                                />
                            </Flex>

                            <Flex as={Center}>
                                <Button disabled={reply === ''} color='white' colorScheme='teal' onClick={replyHandler}>
                                    {t("Reply")}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </>
    )
}

export default DoubtScreen;