import axios from 'axios';
import {
    ORDER_LIST_FAIL,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
} from '../constants/orderConstants';
import serverIp from '../config/config';

export const listOrders = (sortFor, sortTo) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_LIST_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/orders?sortFor=${sortFor}&sortTo=${sortTo}`, config);

		dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};
