import axios from "axios";
import {
  COUPON_CREATE_REQUEST,
  COUPON_CREATE_SUCCESS,
  COUPON_CREATE_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_DETAILS_REQUEST,
  COUPON_DETAILS_SUCCESS,
  COUPON_DETAILS_FAIL,
  COUPON_UPDATE_REQUEST,
  COUPON_UPDATE_SUCCESS,
  COUPON_UPDATE_FAIL,
  COUPON_DELETE_REQUEST,
  COUPON_DELETE_SUCCESS,
  COUPON_DELETE_FAIL,
} from "../constants/couponCostants";
import serverIp from "../config/config";

// Create a new coupon
export const createCoupon = (couponData) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${serverIp}/api/coach/coupons`,
      couponData,
      config
    );

    dispatch({ type: COUPON_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COUPON_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all coupons
export const listCoupons = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${serverIp}/api/coach/coupons`, config);

    dispatch({ type: COUPON_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COUPON_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get a single coupon by ID
export const getCouponDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/coach/coupons/${id}`,
      config
    );

    dispatch({ type: COUPON_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COUPON_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Update a coupon
export const updateCoupon = (id, couponData) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${serverIp}/api/coach/coupons/${id}`,
      couponData,
      config
    );

    dispatch({ type: COUPON_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COUPON_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a coupon
export const deleteCoupon = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`${serverIp}/api/coach/coupons/${id}`, config);

    dispatch({ type: COUPON_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: COUPON_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
