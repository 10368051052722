import {
  Box,
  Flex,
  Button,
  Image,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { TbTrash } from "react-icons/tb";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { deleteEvents } from "../actions/eventsActions";

const EventsListCard = (events) => {
  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteEvents(id));
    }
  };

  return (
    <div className="flex items-center h-32 p-2 bg-white rounded-lg shadow-lg hover:scale-105">
      <img
        className="w-28 h-28 object-cover object-center rounded-md border-2 border-solid border-black"
        src={events.events.thumbImage}
      />
      <div className="flex flex-col w-full pl-2">
        <RouterLink
          to={`/events/${events.events._id}?progId=${events.progId}`}
          className=""
        >
          <h3 className="line-clamp-1 font-semibold">{events.events.name} </h3>
          <Heading as="h6" fontSize="sm" fontWeight="bold" color="blue.600">
            {`${
              new Date(events.events.setTime)
                .toLocaleString("de-DE", {
                  hour12: false,
                })
                .split(",")[0]
            }`}
          </Heading>
          <p className="line-clamp-3 text-sm">{events.events.description}</p>
        </RouterLink>
        {/* <div className="flex w-full justify-between items-center">
          <Heading as="h6" fontSize="sm" fontWeight="bold" color="blue.600">
            {`${new Date(events.events.setTime).toLocaleString("de-DE", {
              hour12: false,
            }).split(',')[0]}`}
          </Heading> */}

        <div className="flex w-full justify-between">
          <h3 className="font-semibold">
            {events.events.price?.toFixed(2).replace(".", ",")} €
          </h3>
          <button onClick={() => deleteHandler(events.events._id)}>
            <Icon
              as={TbTrash}
              boxSize={{ sm: "5", lg: "6" }}
              fontSize="xl"
              fontWeight="bold"
            />
          </button>
        </div>

        {/* <button onClick={() => deleteHandler(events.events._id)}>
            <Icon
              as={TbTrash}
              boxSize={{ sm: "5", lg: "6" }}
              fontSize="xl"
              fontWeight="bold"
            />
          </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default EventsListCard;
