import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoChevronDown } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";

import { logout } from "../actions/userActions";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkLanguage = () => {
    let lang = localStorage.getItem("languageMethod");
    if (!lang) {
      return "de";
    }
    return JSON.parse(lang);
  };

  const [lang, setLang] = useState(checkLanguage);

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    localStorage.setItem("languageMethod", JSON.stringify(language));
    setLang(language);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const [show, setShow] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      // bg='linear-gradient(#5d5959, gray)'
      bg="#dfcaa4"
      px="6"
      w={{ sm: "100%", lg: "full" }}
      top="0"
      pos="fixed"
      zIndex="99"
    >
      <Link as={RouterLink} to="/home">
        <Image src="Logo1.png" py="1" h={{ sm: "60px", md: "75px" }} />
      </Link>

      <Button
        display={{ base: "block", md: "none" }}
        bg="none"
        // onClick={() => setShow(!show)}
        onClick={onOpen}
      >
        <Icon as={HiOutlineMenuAlt3} color="white" w="5" h="5" />
      </Button>

      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Link as={RouterLink} to="/home">
              <Image src="Logo1.png" py="1" h={{ sm: "60px", md: "75px" }} />
            </Link>
          </DrawerHeader>
          <DrawerBody>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Language
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                  display="flex"
                  flexDir="column"
                  alignItems="start"
                  gap="4"
                  py="4"
                >
                  <button onClick={() => changeLanguage("en")}>
                    {t("English")}
                  </button>
                  <button onClick={() => changeLanguage("de")}>
                    {t("Deutsch")}
                  </button>
                </AccordionPanel>
              </AccordionItem>

              {userInfo && userInfo.isCoach && (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {t("Manage")}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    display="flex"
                    flexDir="column"
                    alignItems="start"
                    gap="4"
                    py="4"
                  >
                    <RouterLink to="/orderList">{t("Booking")}</RouterLink>
                    <RouterLink to="/coupons">{t("Coupons")}</RouterLink>
                    <RouterLink to="/messenger">{t("Chat")}</RouterLink>
                  </AccordionPanel>
                </AccordionItem>
              )}

              {userInfo && (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {user.user.name ? user.user.name : userInfo.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    display="flex"
                    flexDir="column"
                    alignItems="start"
                    gap="4"
                    py="4"
                  >
                    <RouterLink to="/profile">{t("Profile")}</RouterLink>
                    <button onClick={logoutHandler}>{t("Logout")}</button>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box
        display={{ base: show ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        my={{ base: 2, md: 0 }}
        lineHeight={{ base: 2, md: 0 }}
        alignItems="center"
      >
        <Menu>
          <MenuButton
            as={Button}
            bg="#ab846a"
            color="black"
            rightIcon={<IoChevronDown />}
            _hover={{ textDecor: "none", opacity: "0.7" }}
          >
            <Icon mt="1" as={MdOutlineLanguage} boxSize="5" />
          </MenuButton>
          <MenuList bgColor="white">
            <MenuItem
              bgColor="white"
              h="10"
              _hover={{ bg: "gray.200", opacity: "1" }}
              onClick={() => changeLanguage("en")}
            >
              {t("English")}
            </MenuItem>
            <MenuItem
              bgColor="white"
              h="10"
              _hover={{ bg: "gray.200", opacity: "1" }}
              onClick={() => changeLanguage("de")}
            >
              {t("Deutsch")}
            </MenuItem>
          </MenuList>
        </Menu>

        {/* Admin Menu */}
        {userInfo && userInfo.isCoach && (
          <Menu>
            <MenuButton
              mt={{ sm: "0", md: "0" }}
              ml={{ sm: "3", md: "5" }}
              bg="#ab846a"
              color="black"
              fontSize="10px"
              fontWeight="bold"
              as={Button}
              textTransform="uppercase"
              _hover={{ textDecoration: "none", opacity: "0.7" }}
            >
              {t("Manage")} <Icon as={IoChevronDown} />
            </MenuButton>
            <MenuList bgColor="white">
              <MenuItem
                bgColor="white"
                _hover={{ bg: "gray.200", opacity: "1" }}
                h="10"
                as={RouterLink}
                to="/orderList"
              >
                {t("Booking")}
              </MenuItem>
              <MenuItem
                bgColor="white"
                _hover={{ bg: "gray.200", opacity: "1" }}
                h="10"
                as={RouterLink}
                to="/coupons"
              >
                {t("Coupons")}
              </MenuItem>
              <MenuItem
                bgColor="white"
                _hover={{ bg: "gray.200", opacity: "1" }}
                h="10"
                as={RouterLink}
                to="/messenger"
              >
                {t("Chat")}
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        <Menu>
          <MenuButton
            ml={{ sm: "3", md: "5" }}
            as={Button}
            bg="#ab846a"
            color="black"
            fontSize="10px"
            fontWeight="bold"
            textTransform="uppercase"
            rightIcon={<IoChevronDown />}
            _hover={{ textDecor: "none", opacity: "0.7" }}
          >
            {user.user.name ? user.user.name : userInfo.name}
          </MenuButton>
          <MenuList bgColor="white">
            <MenuItem
              bgColor="white"
              h="10"
              _hover={{ bg: "gray.200", opacity: "1" }}
              as={RouterLink}
              to="/profile"
            >
              {t("Profile")}
            </MenuItem>
            <MenuItem
              bgColor="white"
              h="10"
              _hover={{ bg: "gray.200", opacity: "1" }}
              onClick={logoutHandler}
            >
              {t("Logout")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default Header;
