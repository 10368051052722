import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Textarea,
  Image,
  AspectRatio,
  Card,
  CardBody,
  CardFooter,
  Text,
  Divider,
  ButtonGroup,
  Select,
} from "@chakra-ui/react";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../components/Loader";
import Message from "../components/Message";
import serverIp from "../config/config";
import FormContainer from "../components/FormContainer";
import { updatePrograms, listProgramDetails } from "../actions/programsActions";
import { PROGRAMS_UPDATE_RESET } from "../constants/programsConstants";
import UploadFile from "../components/UploadFile";
import ImageCropper from "../components/ImageCropper";
import AlertDialogExample from "../components/AlertDialog";
import ReactPlayer from "react-player";

const ProgramEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: programId } = useParams();

  const [name, setName] = useState("");
  const [result, setResult] = useState("");
  const [price, setPrice] = useState(0);
  const [liveStatus, setLiveStatus] = useState(false);
  const [category, setCategory] = useState("Überblick");
  const [thumbImage, setThumbImage] = useState("");
  const [progImage, setProgImage] = useState("");
  const [thumbVideo, setThumbVideo] = useState("");
  const [time, setTime] = useState("");
  const [feedback, setFeedback] = useState("");
  const [description, setDescription] = useState("");
  const [uploadingImg, setUploadingImg] = useState(false);
  const [uploadingVid, setUploadingVid] = useState(false);
  const [media, setMedia] = useState({
    url: null,
    name: "",
    type: "",
    description: "",
  });
  const [mediaSave, setMediaSave] = useState(false);
  const [mediaEdit, setMediaEdit] = useState(false);
  const [isShown, setIsShown] = useState(true);

  const programsDetails = useSelector((state) => state.programsDetails);
  const { loading, error, programs } = programsDetails;

  const programsUpdate = useSelector((state) => state.programsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = programsUpdate;

  const { t } = useTranslation();

  const onMediaChange = (index, type, url, name, description) => {
    setMedia({
      type: type,
      url: url,
      name: name,
      description: description,
    });
    setMediaSave(true);
    setMediaEdit(false);
  };

  const onMediaEdit = (index, type, url, name, description) => {
    setMedia({
      type: type,
      url: url,
      name: name,
      description: description,
    });
    setMediaSave(true);
    setMediaEdit(false);
  };

  const onMediaSave = (index, type, url, name, description) => {
    setMedia({
      type: type,
      url: url,
      name: name,
      description: description,
    });
    setMediaSave(true);
    setMediaEdit(false);
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PROGRAMS_UPDATE_RESET });
      navigate(`/programs/${programId}`);
    } else {
      if (!programs.name || programs._id !== programId) {
        dispatch(listProgramDetails(programId));
      } else {
        setName(programs.name);
        setResult(programs.result);
        setPrice(programs.price);
        setLiveStatus(programs.liveStatus);
        setCategory(programs.category);
        setThumbImage(programs.thumbImage);
        setProgImage(programs.progImage);
        setThumbVideo(programs.thumbVid);
        setTime(convertUTCToLocal(programs.setTime));
        setFeedback(programs.feedbackQ);
        setMedia(
          programs.media
            ? programs.media
            : { url: null, name: "", type: "", description: "" }
        );
        setDescription(programs.description);
      }
    }
  }, [dispatch, navigate, programId, programs, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updatePrograms({
        _id: programId,
        name,
        result,
        thumbImage,
        progImage,
        liveStatus,
        category,
        thumbVid: thumbVideo,
        setTime: time,
        feedbackQ: feedback,
        media: media.url === null || media.url === "" ? null : media,
        description,
        price,
      })
    );
  };

  const convertUTCToLocal = (utcTime) => {
    const dateObj = new Date(utcTime);
    const localizedTime = dateObj.toLocaleString('de-DE', {
      timeZone: "UTC",
      hour12: false,
    }); // Convert to German locale

    const [date, time] = localizedTime.split(', ');
    const [day, month, year] = date.split('.');
    const [hours, minutes, seconds] = time.split(':');

    // Ensure leading zeros for single-digit values
    const formattedMonth = month.length === 1 ? `0${month}` : month;
    const formattedDay = day.length === 1 ? `0${day}` : day;
    const formattedHours = hours.length === 1 ? `0${hours}` : hours;
    const formattedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;

    const datetimeLocal = `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}`;
    return datetimeLocal;
  };

  const uploadVidHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("video", file);
    setUploadingVid(true);

    if (file) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${serverIp}/api/uploadVid/progVid`,
          formData,
          config
        );
        setThumbVideo(data);
        setUploadingVid(false);
      } catch (err) {
        console.error(err);
        setUploadingVid(false);
      }
    } else {
      setUploadingVid(false);
    }
  };

  const deleteVidHandler = async (e) => {
    e.preventDefault();
    setThumbVideo("");
  };
  const handleMediaObjectDelete = async (e) => {
    e.preventDefault();
    setMedia({ url: null, name: "", type: "", description: "" });
    // setMediaSave(false);
  };
  const handleMediaObjectEdit = async (e) => {
    e.preventDefault();
    setMediaEdit(true);
  };

  return (
    <Flex direction="column" mt="5px">
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        px={{ sm: "4px", md: "" }}
      >
        <FormContainer>
          <Heading as="h1" fontSize="3xl" color="gray.800">
            {t("Edit Program")}
          </Heading>

          {loadingUpdate && <Loader />}
          {errorUpdate && <Message type="error">{errorUpdate}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            //onSubmit={submitHandler}
            <form onSubmit={submitHandler}>
              <Flex
                direction={{ sm: "column", md: "row" }}
                flexWrap={{ sm: "nowrap", md: "wrap" }}
                rowGap={{ sm: "10px", md: "15px" }}
                columnGap={{ sm: "0", md: "10px" }}
              >
                {/* NAME */}
                <FormControl
                  id="name"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="gray.800">{t("Name")}</FormLabel>
                  <Input
                    color="gray.800"
                    type="text"
                    borderColor="gray.800"
                    placeholder={t("Enter name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>

                {/* Set Result */}
                <FormControl
                  id="result"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="gray.800">{t("Result")}</FormLabel>
                  <Input
                    color="gray.800"
                    type="text"
                    borderColor="gray.800"
                    placeholder={t("Enter result")}
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                  />
                </FormControl>

                {/* Thumb IMAGE */}
                <FormControl id="thumbimage" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="gray.800">{t("Cover Image")}</FormLabel>
                  <Flex justifyContent="center">
                    <Flex w="full">
                      <ImageCropper
                        route="uploads/progImg"
                        setIsShown={setIsShown}
                        thumbImage={thumbImage}
                        setThumbImage={setThumbImage}
                        aspectRatio={1 / 1}
                      />
                    </Flex>
                  </Flex>
                  {uploadingImg && <Loader />}
                </FormControl>

                {/* Prog IMAGE */}
                <FormControl id="progimage" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="gray.800">{t("Program Image")}</FormLabel>
                  <Flex justifyContent="center">
                    <Flex w="full">
                      <ImageCropper
                        route="uploads/progImg"
                        setIsShown={setIsShown}
                        thumbImage={progImage}
                        setThumbImage={setProgImage}
                        aspectRatio={16 / 9}
                      />
                    </Flex>
                  </Flex>
                  {uploadingImg && <Loader />}
                </FormControl>

                {/* Video */}
                <FormControl id="thumbvideo" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="gray.800">
                    {t("Introduction Video")}
                  </FormLabel>
                  {/* <Flex as={AspectRatio} ratio={16 / 9} display={thumbVideo !== '' ? 'flex' : 'none'} justifyContent='center'>
										<video src={thumbVideo} controls />
									</Flex> */}
                  <Flex gap="3" justifyContent="center">
                    {/* <Input
											display={thumbVideo === '' ? 'flex' : 'none'}
											pt='4px'
											color='gray.800'
											borderColor='gray.800'
											type='file'
											accept='video/*'
											id='video-file'
											onChange={uploadVidHandler}
										/> */}
                    <Input
                      // display={thumbVideo === '' ? 'flex' : 'none'}
                      pt="4px"
                      value={thumbVideo}
                      placeholder="Enter Video Link"
                      color="gray.800"
                      borderColor="black"
                      type="text"
                      onChange={(e) => setThumbVideo(e.target.value)}
                    />
                    {/* <Button
											marginTop='10px'
											colorScheme='red'
											color='white'
											display={thumbVideo === '' ? 'none' : 'flex'}
											onClick={deleteVidHandler}
										>
											{t("Remove")}
										</Button> */}
                  </Flex>
                  {uploadingVid && <Loader />}
                </FormControl>

                {/* Set Time */}
                <FormControl id="setTime" width={{ sm: "full", md: "49%" }}>
                  <FormLabel color="gray.800">{t("Set Date-Time")}</FormLabel>
                  <Input
                    color="gray.800"
                    css={`
                      ::-webkit-calendar-picker-indicator {
                        filter: invert(0);
                      }
                    `}
                    type="datetime-local"
                    borderColor="gray.800"
                    placeholder={t("Enter date time")}
                    // min={`${new Date().toISOString().split(":")[0]}:${
                    //   new Date().toISOString().split(":")[1]
                    // }`}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </FormControl>

                {/* Category */}
                <FormControl
                  id="category"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color={"gray.800"}>{t("Category")}</FormLabel>
                  <Select
                    color="gray.800"
                    borderColor="gray.800"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    <option value="Überblick" style={{ color: "black" }}>
                      {"Überblick"}
                    </option>
                    <option value="Resilienz" style={{ color: "black" }}>
                      {"Resilienz"}
                    </option>
                    <option value="Schlaf" style={{ color: "black" }}>
                      {"Schlaf"}
                    </option>
                    <option value="Gesundheit" style={{ color: "black" }}>
                      {"Gesundheit"}
                    </option>
                    <option value="Stressmanagement" style={{ color: "black" }}>
                      {"Stressmanagement"}
                    </option>
                    <option value="Selbstwertschätzung" style={{ color: "black" }}>
                      {"Selbstwertschätzung"}
                    </option>
                  </Select>
                </FormControl>

                {/* Live status */}
                <FormControl
                  id="live"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color={"gray.800"}>
                    {t("Program Status")}
                  </FormLabel>
                  <Select
                    color="gray.800"
                    borderColor="gray.800"
                    onChange={(e) => setLiveStatus(e.target.value)}
                    value={liveStatus}
                  >
                    <option value="false" style={{ color: "black" }}>
                      Offline
                    </option>
                    <option value="true" style={{ color: "black" }}>
                      Live
                    </option>
                  </Select>
                </FormControl>

                {/* FEEDBACK QUESTION */}
                <FormControl
                  id="feedback"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color={"gray.800"}>
                    {t("Feedback Question")}
                  </FormLabel>
                  <Input
                    color="gray.800"
                    type="text"
                    borderColor="gray.800"
                    placeholder={t("Enter Feedback Question")}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </FormControl>

                {/* PRICE */}
                <FormControl
                  id="price"
                  isRequired
                  width={{ sm: "full", md: "49%" }}
                >
                  <FormLabel color="gray.800">{t("Price")}</FormLabel>
                  <InputGroup>
                    <Input
                      color="gray.800"
                      type="number"
                      borderColor="gray.800"
                      placeholder="Enter price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <InputRightAddon borderColor="gray.800" children="€" />
                  </InputGroup>
                </FormControl>

                {/* DESCRIPTION */}
                <FormControl
                  id="description"
                  isRequired
                  width={{ sm: "full", md: "99%" }}
                >
                  <FormLabel color="gray.800">{t("Description")}</FormLabel>
                  <Input
                    as={Textarea}
                    color="gray.800"
                    type="text"
                    borderColor="gray.800"
                    placeholder={t("Enter description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>

                {/* Additional Media */}
                <FormControl
                  id="media"
                  width={{ sm: "full", md: "99%" }}
                  color="gray.800"
                >
                  <FormLabel color="gray.800">
                    {t("Additional Media")}
                  </FormLabel>
                  <Flex display={media.url == null ? "flex" : "none"}>
                    <UploadFile
                      media={media}
                      onMediaChange={onMediaChange}
                      onMediaEdit={onMediaEdit}
                      onMediaSave={onMediaSave}
                      mediaEdit={mediaEdit}
                      mediaFor="program"
                    />
                  </Flex>
                  <Flex display={media.url != null ? "flex" : "none"}>
                    <Card maxW="sm" bgColor="white">
                      <CardBody>
                        <Flex
                          display={media.type === "image" ? "flex" : "none"}
                        >
                          <Image
                            src={media.url}
                            alt={media.name}
                            borderRadius="lg"
                          />
                        </Flex>

                        <Flex
                          display={media.type === "video" ? "flex" : "none"}
                        >
                          {/* <video src={media.url} controls /> */}
                          <ReactPlayer
                            url={media?.url}
                            controls
                            width="450px"
                            height="290px"
                          />
                        </Flex>
                        <Text color="black" fontSize="xl" fontWeight="bold">
                          {media.name}
                        </Text>
                        <Text color="black">{media.description}</Text>
                      </CardBody>
                      <Divider />
                      <CardFooter>
                        <ButtonGroup spacing="2">
                          <Button
                            variant="solid"
                            colorScheme="blue"
                            onClick={handleMediaObjectEdit}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="ghost"
                            colorScheme="blue"
                            onClick={handleMediaObjectDelete}
                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </CardFooter>
                    </Card>
                  </Flex>
                </FormControl>

                <Flex gap="5" mt="6">
                  <Button
                    colorScheme="teal"
                    color="white"
                    type="submit"
                    isLoading={loading}
                  >
                    {t("Done")}
                  </Button>
                  <AlertDialogExample />
                </Flex>
              </Flex>
            </form>
          )}
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default ProgramEditScreen;
