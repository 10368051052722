import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiFilter, BiSort } from "react-icons/bi";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";

import { listOrders } from "../actions/orderActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const OrderListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [sortFor, setSortFor] = useState("createdAt");
  const [sortTo, setSortTo] = useState(true);
  const [paidStatus, setPaidStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    paidStatus === "all"
      ? orders?.orders?.slice(indexOfFirstItem, indexOfLastItem)
      : orders?.orders
          ?.filter(
            (order) => paidStatus === "all" || order.isPaid === paidStatus
          )
          .slice(indexOfFirstItem, indexOfLastItem);

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo && userInfo.isCoach) {
      dispatch(listOrders(sortFor, sortTo));
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate, sortFor, sortTo]);

  return (
    <Flex direction="column" w="full">
      <Flex h="full" direction="column" w={{ sm: "390px", lg: "full" }}>
        <Flex
          py="4"
          bg="#dfcaa4"
          px={{ sm: "5px", lg: "40px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading fontSize="xl" as={RouterLink} to="/home" color="white">
            {t("Home")}
          </Heading>
          <Heading fontSize="xl" color="white">
            {t("Orders")}
          </Heading>
        </Flex>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5">
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  {/* <Th fontSize={{ sm: "11px", md: "sm" }}>{t("IMAGE")}</Th> */}
                  <Th fontSize={{ sm: "11px", md: "sm" }}>{t("USER")}</Th>
                  <Th fontSize={{ sm: "11px", md: "sm" }}>{t("TYPE")}</Th>
                  <Th fontSize={{ sm: "11px", md: "sm" }}>{t("Name")}</Th>
                  {/* <Th display={{ sm: "none", md: "table-cell" }}>
                    {t("EMAIL")}
                    </Th> */}
                  <Th
                    display={{ sm: "none", md: "table-cell" }}
                    onClick={() => {
                      setSortFor("createdAt");
                      setSortTo((prevState) => !prevState);
                    }}
                  >
                    <div className="flex">
                      {t("DATE")}
                      <BiSort />
                    </div>
                  </Th>
                  {/* <Th display={{sm:'none', md:'table-cell'}}>{t("POSITION")}</Th> */}
                  {/* <Th display={{sm:'none', md:'table-cell'}}>{t("ORGANIZATION")}</Th> */}
                  {/* <Th fontSize={{sm:'11px',md:'sm'}} display={{sm:'none', md:'table-cell'}}>{t("ORDER ID")} {"."}</Th> */}
                  {/* <Th
                    fontSize={{ sm: "11px", md: "sm" }}
                    display={{ sm: "table-cell", md: "none" }}
                    onClick={() => {
                      setSortFor("price");
                      setSortTo((prevState) => !prevState);
                      }}
                      >
                      <div className="flex">
                      {t("ORDER ID")} {"& PRICE"}
                      <BiSort />
                      </div>
                      </Th> */}
                  <Th fontSize={{ sm: "11px", md: "sm" }}>{t("COUPON")}</Th>
                  <Th
                    fontSize={{ sm: "11px", md: "sm" }}
                    display={{ sm: "none", md: "table-cell" }}
                    onClick={() => {
                      setSortFor("price");
                      setSortTo((prevState) => !prevState);
                    }}
                  >
                    <div className="flex">
                      {t("PRICE")}
                      <BiSort />
                    </div>
                  </Th>
                  <Th>
                    <div className="flex relative peer group">
                      {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                      <div>
                        Paid Status(
                        {paidStatus === true
                          ? "Paid"
                          : paidStatus === false
                          ? "Unpaid"
                          : "All"}
                        )
                      </div>
                      <BiFilter />
                    </div>
                    <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                      <button
                        onClick={() => setPaidStatus("all")}
                        className="px-2 py-4 hover:bg-gray-200"
                      >
                        All
                      </button>
                      <button
                        onClick={() => setPaidStatus(true)}
                        className="px-2 py-4 hover:bg-gray-200"
                      >
                        Paid
                      </button>
                      <button
                        onClick={() => setPaidStatus(false)}
                        className="px-2 py-4 hover:bg-gray-200"
                      >
                        Unpaid
                      </button>
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems &&
                  currentItems.map((order) => (
                    <Tr color={!order.isPaid && "red"}>
                      {/* <Td>
                        <Image
                          src={order.user.image}
                          h={{ sm: "35px", lg: "70px" }}
                          w={{ sm: "35px", lg: "70px" }}
                        />
                      </Td> */}
                      <Td>
                        <Text
                          fontSize={{ sm: "12px", md: "sm" }}
                          fontWeight="bold"
                        >
                          {order.user.name}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize={{ sm: "12px", md: "sm" }}
                          fontWeight="bold"
                        >
                          {order.programme ? "Programme" : "Event"}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize={{ sm: "12px", md: "sm" }}
                          fontWeight="bold"
                        >
                          {order.itemName ? order.itemName : order.programName}
                        </Text>
                      </Td>
                      {/* <Td display={{ sm: 'none', md:'table-cell'}}>
										<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
									</Td> */}
                      <Td display={{ sm: "none", md: "table-cell" }}>
                        {order.createdAt.substring(0, 10)}
                      </Td>
                      {/* <Td display={{sm:'none', md:'table-cell'}}>{order.user.position}</Td> */}
                      {/* <Td display={{sm:'none', md:'table-cell'}}>{order.user.orgName}</Td> */}
                      {/* <Td >
										<Flex direction='column'>
											<Text fontSize={{sm:'10px',md:'sm'}}>{order._id}</Text>
											<Text fontSize={{sm:'10px',md:'sm'}} display={{sm:'flex', md:'none'}}>{order.price.toFixed(2)} €</Text>
										</Flex>
									</Td> */}
                      <Td
                        fontSize={{ sm: "10px", md: "sm" }}
                        display={{ sm: "none", md: "table-cell" }}
                      >
                        {order.coupon ? order.couponCode : "N/A"}
                      </Td>
                      <Td
                        fontSize={{ sm: "10px", md: "sm" }}
                        display={{ sm: "none", md: "table-cell" }}
                      >
                        {order.finalPrice
                          ? order.finalPrice.toFixed(2)
                          : order.price.toFixed(2)}{" "}
                        €
                      </Td>
                      <Td>
                        <span
                          className={
                            order.isPaid ? "status completed" : "status pending"
                          }
                        >
                          {order.isPaid ? "Paid" : "Unpaid"}
                        </span>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
              <div className="flex items-center gap-2 pt-4">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaRegArrowAltCircleLeft className="w-8 h-8" />
                </button>
                {currentPage}
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={indexOfLastItem >= orders.length}
                >
                  <FaRegArrowAltCircleRight className="w-8 h-8" />
                </button>

                <select
                  className="border-2 border-solid border-black py-1.5 px-0.5 "
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                </select>
              </div>
            </Table>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default OrderListScreen;
