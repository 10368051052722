import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

import {
  userLoginReducer,
  bgImageUpdateReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userDoubtListReducer,
  userListDetailsReducer,
  feedbackUserListReducer,
  feedbackDetailsReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from "./reducer/userReducer";
import {
  programsListReducer,
  programsDetailsReducer,
  programsCreateReducer,
  programsDeleteReducer,
  programsUpdateReducer,
  feedbackProgramsListReducer,
} from "./reducer/programsReducer";

import {
  eventsListReducer,
  eventsDetailsReducer,
  eventsCreateReducer,
  eventsDeleteReducer,
  eventsUpdateReducer,
  progEventsListReducer,
  eventsCopyReducer,
  allProgEventsListReducer,
} from "./reducer/EventsReducer";

import {
  tasksListReducer,
  tasksDetailsReducer,
  tasksCreateReducer,
  tasksDeleteReducer,
  tasksUpdateReducer,
  tasksAssignReducer,
  userTasksListReducer,
  progTasksListReducer,
  tasksCopyReducer,
  allProgTasksListReducer,
} from "./reducer/tasksReducer";

import { doubtsDetailsReducer } from "./reducer/doubtReducer";

import { orderListReducer } from "./reducer/orderReducer";
import {
  supportDetailsReducer,
  aboutusDetailsReducer,
  supportUpdateReducer,
  aboutUsUpdateReducer,
} from "./reducer/infoReducer";

import { convDeleteReducer } from "./reducer/chatReducer";
import {
  tokenCreateReducer,
  tokenDeleteReducer,
  tokenDetailsReducer,
  tokenListReducer,
  tokenUpdateReducer,
} from "./reducer/fcmpnReducer";

import {
  couponCreateReducer,
  couponListReducer,
  couponDetailsReducer,
  couponUpdateReducer,
  couponDeleteReducer,
} from './reducer/couponReducer';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  bgImageUpdate: bgImageUpdateReducer,
  programsList: programsListReducer,
  programsDetails: programsDetailsReducer,
  eventsList: eventsListReducer,
  eventsDetails: eventsDetailsReducer,
  programsCreate: programsCreateReducer,
  programsDelete: programsDeleteReducer,
  programsUpdate: programsUpdateReducer,
  eventsCreate: eventsCreateReducer,
  eventsCopy: eventsCopyReducer,
  eventsDelete: eventsDeleteReducer,
  eventsUpdate: eventsUpdateReducer,
  tasksList: tasksListReducer,
  tasksDetails: tasksDetailsReducer,
  tasksCreate: tasksCreateReducer,
  tasksCopy: tasksCopyReducer,
  tasksDelete: tasksDeleteReducer,
  tasksUpdate: tasksUpdateReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userDoubtList: userDoubtListReducer,
  orderList: orderListReducer,
  doubtsDetails: doubtsDetailsReducer,
  tasksAssign: tasksAssignReducer,
  userTasksList: userTasksListReducer,
  userListDetails: userListDetailsReducer,
  feedbackProgramsList: feedbackProgramsListReducer,
  feedbackUserList: feedbackUserListReducer,
  feedbackDetails: feedbackDetailsReducer,
  supportDetails: supportDetailsReducer,
  aboutusDetails: aboutusDetailsReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  supportUpdate: supportUpdateReducer,
  aboutUsUpdate: aboutUsUpdateReducer,
  progTasksList: progTasksListReducer,
  allProgTasksList: allProgTasksListReducer,
  progEventsList: progEventsListReducer,
  allProgEventsList: allProgEventsListReducer,
  convDelete: convDeleteReducer,
  tokenUpdate: tokenUpdateReducer,
  couponCreate: couponCreateReducer,
  couponList: couponListReducer,
  couponDetails: couponDetailsReducer,
  couponUpdate: couponUpdateReducer,
  couponDelete: couponDeleteReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const imgInfoFromStorage = localStorage.getItem("imgInfo")
  ? JSON.parse(localStorage.getItem("imgInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage, imgInfo: imgInfoFromStorage },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
